import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Form, Input, Space, Table, Flex, Tooltip, Badge } from 'antd';
import CameraModal from '../../../media/Camera';
import SignCanvas from '../../../media/SignCanvas';

const NewInventoryExit = ({ open, setOpen, exitInfo }) => {
    const [form] = Form.useForm();
    const [exit, setExit] = useState(exitInfo);
    const [images, setImages] = useState([]);
    const [openCamera, setOpenCamera] = useState(false);
    const [openSign, setOpenSign] = useState(false);
    const [clientSignature, setClientSignature] = useState(null);
    const [carrierSignature, setCarrierSignature] = useState(null);

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            console.log(values);
            setOpen(false);
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        console.log(exitInfo);
        setExit(exitInfo);
    }, [exitInfo]);



    return (
        <Modal
            title="Nueva salida de almacén"
            open={open}
            onCancel={() => setOpen(false)}
            style={{ top: 20 }}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Space direction="vertical">
            <h2>Venta: {exit?.SalesOrder}</h2>
            <h3>Cliente: {exit?.Client}</h3>
            <h3>Recibe: {exit?.DeliveryOptions === 'Flete' ? 'Transportista' : 'Cliente'}</h3>
           <Table
                dataSource={exit?.Products}
                columns={[
                    {
                        title: "Producto",
                        dataIndex: "name",
                        key: "name",
                        render: (text) => (
                            <p>{text}</p>
                        ),
                    },
                    {
                        title: "Cantidad",
                        dataIndex: "quantity",
                        key: "quantity",
                        render: (text, product) => (
                            <p>{text} {product.baseUnit?.toLowerCase()}</p>
                        ),
                    },
                    {
                        title: 'Entregado',
                        dataIndex: 'delivered',
                        key: 'delivered',
                        render: (text, product) => (
                            <Input 
                                style={{ color: parseInt(product.quantity) === parseInt(text) ? 'green' : 'red' }} 
                                onChange={(e) => {
                                    const products = exit.Products
                                        .map((p) => {
                                            if (p.name === product.name) {
                                                return { ...p, delivered: e.target.value };
                                            }
                                            return p;
                                        });
                                    setExit({ ...exit, products });
                                }}
                            />
                                
                        )
                    }
                ]}
                rowKey={(record, index) => index}
                pagination={false}
            />
            <Flex direction="horizontal" style={{ marginTop: 20, overflowX: 'scroll' }}>
                {images && images.map((image, index) => (
                    <Space direction="vertical" style={{ marginRight: 10, paddingTop: 10 }}>
                        <Badge title="Eliminar" onClick={() => setImages(images.filter((_, i) => i !== index))} count="X" style={{ cursor: 'pointer' }}>
                            <img key={index} src={image} alt={`Foto ${index}`} style={{ width: 90, height: 160, objectFit: 'cover' }} />
                        </Badge>
                    </Space>
                ))}
            </Flex>
            <CameraModal open={openCamera} setOpen={setOpenCamera} onImageTook={setImages} />
            
            {clientSignature && (
                <Space direction="vertical">
                    <h3>Firma del cliente</h3>
                    <img src={clientSignature.image} alt={clientSignature.name} style={{ width: 100, height: 100, objectFit: 'cover' }} />
                    <p>{clientSignature.name}</p>
                </Space>
            )}
            {carrierSignature && (
                <Space direction="vertical">
                    <h3>Firma del transportista</h3>
                    <img src={carrierSignature.image} alt={carrierSignature.name} style={{ width: 100, height: 100, objectFit: 'cover' }} />
                    <p>{carrierSignature.name}</p>
                </Space>
            )}
            <SignCanvas open={openSign} setOpen={setOpenSign} onSave={(image, name) => {
                if (openSign === 'client') {
                    setClientSignature({ image, name });
                } else {
                    setCarrierSignature({ image, name });
                }
            }} />
            <Space direction="horizontal">
            <Button type="primary" onClick={() => setOpenCamera(true)}>Agregar foto</Button>
            {exit?.DeliveryOptions === 'Flete' ? (
                <Button type="primary" onClick={() => setOpenSign('carrier')}>Firma del transportista</Button>
            ) : (
                <Button type="primary" onClick={() => setOpenSign('client')}>Firma del cliente</Button>
            )}
            </Space>
            </Space>
        </Modal>
    );
}

export default NewInventoryExit;
