import React, { Component } from "react";
import { Card, Col, Row, Table, Typography, Tag, Avatar, Statistic } from "antd";
import animalia from "../../images/animale.jpeg";

const { Title } = Typography;

const ClientsList = () => {
  const dataSource = [
    {
      img: "https://cdn.pixabay.com/photo/2016/03/31/20/41/cat-1295971_1280.png",
      name: 'Animalia',
      state: 'Jalisco',
      email: 'animalia@gmail.com',
      seller: 'NA',
      phone: '37812345678',
      seller: "Mariana Aldrete",
      sales: '150 Kg',
      balance: '$185,900.30',
    },
    {
      img: "https://cdn.pixabay.com/photo/2016/12/10/08/09/alphabet-1896903_1280.png",
      name: 'Comercial Cabrera',
      state: 'Jalisco',
      email: 'C.Cabrera@hotmail.com',
      seller: 'NA',
      phone: '3787810164',
      sales: '150 Kg',
      balance: '$-20,456.00',
    },
    {
      img: 'https://cdn.pixabay.com/photo/2014/04/03/10/00/shopping-cart-309592_1280.png',
      name: 'Macro Super Victoria',
      state: 'Morelos',
      email: 'Supervictoria@hotmail.com',
      seller: 'Fernando López',
      phone: '378123456',
      sales: '50 Kg',
      balance: '$5,527.00',
    },
    {
      img: "https://i.pinimg.com/564x/e7/47/d5/e747d5bb64501602bcbc763c70745ecd.jpg",
      name: 'Tienda El Sol',
      state: 'Jalisco',
      email: 'elsol.tienda@gmail.com',
      seller: 'Emanuel Martín',
      phone: '3312345678',
      sales: '120 Kg',
      balance: '$15,678.50',
    },
    {
      img: 'https://i.pinimg.com/564x/4a/85/4b/4a854b61378d21cdaf96e1642c958026.jpg',
      name: 'Distribuidora García',
      state: 'Estado de México',
      email: 'distribucion.garcia@yahoo.com',
      seller: 'NA',
      phone: '3334567890',
      sales: '90 Kg',
      balance: '$-1,345.67',
    },
    {
      img: "https://i.pinimg.com/564x/50/a8/ac/50a8acff8e2ea5d4b5ca1390066b7b0f.jpg",
      name: 'Supermercado Don Chente',
      state: 'Aguascalientes',
      email: 'donchente.supermercado@hotmail.com',
      seller: 'NA',
      phone: '3333333333',
      sales: '200 Kg',
      balance: '$25,000.00',
    },
    {
      img: "https://i.pinimg.com/564x/5d/a0/99/5da099113cb3767f53e2cb3b6b0b9f2e.jpg",
      name: 'Ferretería La Herradura',
      state: 'Jalisco',
      email: 'laherradura.ferreteria@gmail.com',
      seller: 'NA',
      phone: '3229876543',
      sales: '80 Kg',
      balance: '$8,900.00',
    },
    {
      img: 'https://i.pinimg.com/564x/ff/11/9c/ff119cae8edaa7d250bb23637f071b60.jpg',
      name: 'Panadería La Esperanza',
      state: ' Jalisco',
      email: 'esperanza.panaderia@yahoo.com',
      seller: 'Alejandro Ramírez',
      phone: '3311112222',
      sales: '180 Kg',
      balance: '$19,876.00',
    },
    {
      img: "https://i.pinimg.com/564x/34/f4/e3/34f4e3a31ba44616438cf16699767280.jpg",
      name: 'Papelería El Universo',
      state: 'Queretaro',
      email: 'universo.papeleria@gmail.com',
      seller: 'NA',
      phone: '3333334444',
      sales: '100 Kg',
      balance: '$10,500.00',
    },
    {
      img: "https://i.pinimg.com/564x/3f/8b/1f/3f8b1fa72fa35b91ac088d038bf322f0.jpg",
      name: 'Carnicería La Tradición',
      state: 'Jalisco',
      email: 'latradicion.carniceria@hotmail.com',
      seller: 'Jaime Aldrete',
      phone: '3339998888',
      sales: '150 Kg',
      balance: '$15,000.00',
    },
    {
      img: 'https://i.pinimg.com/564x/74/79/c9/7479c9ebf07aa9444c56926b52b42ad4.jpg',
      name: 'Librería El Saber',
      state: 'Jalisco',
      email: 'saber.libreria@yahoo.com',
      seller: 'NA',
      phone: '3225554444',
      sales: '70 Kg',
      balance: '$7,890.00',
    },
    {
      img: "https://i.pinimg.com/564x/10/fc/98/10fc98c33d58946a08d860c98fb92a05.jpg",
      name: 'Restaurante La Parrilla',
      state: 'Jalisco',
      email: 'laparrilla.restaurante@gmail.com',
      seller: 'NA',
      phone: '3312345678',
      sales: '250 Kg',
      balance: '$30,000.00',
    },
    {
      img: "https://i.pinimg.com/564x/f4/27/97/f427976ed71ff001d4c52277521fd6b7.jpg",
      name: 'Tortillería La Nueva Era',
      state: ' Jalisco',
      email: 'nuevaera.tortilleria@hotmail.com',
      seller: 'Emanuel Martín',
      phone: '3338889999',
      sales: '120 Kg',
      balance: '$12,345.00',
    },
    {
      img: "https://i.pinimg.com/564x/b0/ab/f2/b0abf2348268279f5999699a7bdd0dc6.jpg",
      name: 'Zapatería El Caminante',
      state: 'Jalisco',
      email: 'elcaminante.zapateria@yahoo.com',
      seller: 'NA',
      phone: '3336667777',
      sales: '90 Kg',
      balance: '$9,000.00',
    },
    {
      img: "https://i.pinimg.com/564x/b9/f2/10/b9f210d1fc852907ce33afbc767e385a.jpg",
      name: 'Boutique La Moda',
      state: 'Jalisco',
      email: 'lamoda.boutique@gmail.com',
      seller: 'Fernando López',
      phone: '3223332222',
      sales: '200 Kg',
      balance: '$22,000.00',
    },
    {
      img: 'https://i.pinimg.com/564x/45/12/20/4512200a5ea78c265044095894735057.jpg',
      name: 'Cafetería El Aroma',
      state: 'Jalisco',
      email: 'elaroma.cafeteria@hotmail.com',
      seller: 'NA',
      phone: '3317778888',
      sales: '150 Kg',
      balance: '$15,678.90',
    },
    {
      img: 'https://i.pinimg.com/564x/bd/d4/44/bdd444366a6c1827cd73fd0fc1182d7f.jpg',
      name: 'Pizzería La Italiana',
      state: 'Calle Roma #456, Colonia Roma, Zapopan, Jalisco',
      email: 'italiana.pizzeria@yahoo.com',
      seller: 'Emanuel Martín',
      phone: '3332221111',
      sales: '180 Kg',
      balance: '$-18,000.00',
    },
    {
      img: 'https://i.pinimg.com/564x/53/24/ef/5324efe5f85c8c205ed06a485d033a23.jpg',
      name: 'Ferretería El Martillo',
      state: "Quintana Roo" ,
      email: 'elmartillo.ferreteria@gmail.com',
      seller: 'NA',
      phone: '3333333333',
      sales: '80 Kg',
      balance: '$8,500.00',
    },
    {
      img: "https://i.pinimg.com/736x/95/9e/ff/959eff33b695681000ebc6813d32ad69.jpg",
      name: 'Pastelería Dulce Sabor',
      state: 'Hidalgo',
      email: 'dulcesabor.pasteleria@yahoo.com',
      seller: 'Alejandro Ramírez',
      phone: '3224445555',
      sales: '130 Kg',
      balance: '$13,500.00',
    },
    {
      img: 'https://i.pinimg.com/564x/25/de/69/25de69b8903ad8b7a3c2b7fce0b6ab38.jpg',
      name: 'Farmacia La Salud',
      state: 'Nuevo León',
      email: 'lasalud.farmacia@hotmail.com',
      seller: 'NA',
      phone: '3311110000',
      sales: '160 Kg',
      balance: '$16,789.00',
    },
    {
      img: 'https://i.pinimg.com/564x/45/ec/d7/45ecd7076e4c86cd5123b5f6b5b61804.jpg',
      name: 'Papelería El Estudiante',
      state: 'Baja California',
      email: 'elestudiante.papeleria@gmail.com',
      seller: 'Mariana Aldrete',
      phone: '3338889999',
      sales: '110 Kg',
      balance: '$11,234.00',
    },
    {
      img: "https://i.pinimg.com/474x/04/cb/21/04cb21cf8c2893affec3eb6a8127e761.jpg",
      name: 'Carnicería El Rancho',
      state: 'Colima',
      email: 'elrancho.carniceria@yahoo.com',
      seller: 'NA',
      phone: '3336667777',
      sales: '140 Kg',
      balance: '$14,000.00',
    },
    {
      img: "https://i.pinimg.com/564x/2a/d9/5f/2ad95f2d0b342fd00bfad6bd5997db76.jpg",
      name: 'Librería La Cultural',
      state: 'Zacatecas',
      email: 'lacultural.libreria@hotmail.com',
      seller: 'Jaime Aldrete',
      phone: '3225554444',
      sales: '95 Kg',
      balance: '$9,876.00',

    },
  ];

  const renderBalance = (text) => {
    // Extraer el valor numérico del balance
    const balanceValue = parseFloat(text.replace(/[^\d.-]/g, ""));

    // Determinar el color basado en el valor numérico
    const color = balanceValue >= 0 ? "green" : "red";

    // Aplicar estilo condicional al componente <h3>
    return (
      <h3 style={{ color: color }}>
        {text}
      </h3>
    );
  };


  const columns = [
    {
      dataIndex: "img",
      key: "img",
      render: (img) => <img src={img} alt="" className="avatar-sm mr-10" style={{ width: "100%" }} />,
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <>
          <h3>{text}</h3>
        </>
      ),
    },
    {
      title: "Estado",
      dataIndex: "state",
      key: "state",
      render: (text) => (
        <>
          <p>{text}</p>
        </>
      ),
    },
    {
      title: "Correo electrónico",
      dataIndex: "email",
      key: "email",
      render: (text) => (
        <>
          <p>{text}</p>
        </>
      ),
    },
    {
      title: "Número de teléfono",
      dataIndex: "phone",
      key: "phone",
      render: (text) => (
        <>
          <p>{text}</p>
        </>
      ),
    },
    {
      title: "Vendedor",
      dataIndex: "seller",
      key: "seller",
      render: (text) => (
        <>
          <p>{text}</p>
        </>
      ),
    },
    {
      title: "Volumen de ventas",
      dataIndex: "sales",
      key: "sales",
      render: (text) => (
        <>
          <p>{text}</p>
        </>
      ),
    },

    {
      title: "Balance financiero",
      dataIndex: "balance",
      key: "balance",
      render: renderBalance,
    },
  ];



  return (
    <div className="layout-content">
      <Row>
        <Col lg={28} className="zindex">
          <h2 className="p-15 h2">Clientes</h2>
        </Col>
        <Col>
        <Statistic
            title="Número de Clientes"
            value={dataSource.length}
            valueStyle={{ color: '#3f8600' }}
            suffix="clientes"
            style={{padding:"20px"}}
          />
          </Col>
      </Row>
      <Row>
        <Card
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          className="criclebox cardbody mb-24 header-solid"
        >
         
          <div className="ant-list-box table-responsive">
            <Table
              dataSource={dataSource}
              columns={columns}
              rowKey={(record, index) => index}
              pagination={true}
            />
          </div>
        </Card>
      </Row >
    </div >
  );
};

export default ClientsList;
