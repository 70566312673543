import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Form, Input, Space, Table, Flex, Tooltip, Badge } from 'antd';
import CameraModal from '../../../media/Camera';
import Ribbon from 'antd/es/badge/Ribbon';
const NewInventoryEntry = ({ open, setOpen, entryInfo }) => {
    const [form] = Form.useForm();
    const [entry, setEntry] = useState(entryInfo);
    const [images, setImages] = useState([]);
    const [openCamera, setOpenCamera] = useState(false);


    const handleSubmit = () => {
        form.validateFields().then((values) => {
            console.log(values);
            setOpen(false);
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        setEntry(entryInfo);
    }, [entryInfo]);

    return (
        <Modal
            title="Nueva entrada de inventario"
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <h2>Orden de compra: {entry?.purchaseOrder}</h2>
            <h3>Proveedor: {entry?.supplier}</h3>
           <Table
                dataSource={entry?.products}
                columns={[
                    {
                        title: "Producto",
                        dataIndex: "name",
                        key: "name",
                        render: (text) => (
                            <p>{text}</p>
                        ),
                    },
                    {
                        title: "Cantidad",
                        dataIndex: "quantity",
                        key: "quantity",
                        render: (text, product) => (
                            <p>{text} {product.baseUnit?.toLowerCase()}</p>
                        ),
                    },
                    {
                        title: 'Recibido',
                        dataIndex: 'received',
                        key: 'received',
                        render: (text, product) => (
                            <Input 
                                style={{ color: parseInt(product.quantity) === parseInt(text) ? 'green' : 'red' }} 
                                onChange={(e) => {
                                    const products = entry.products
                                        .map((p) => {
                                            if (p.name === product.name) {
                                                return { ...p, received: e.target.value };
                                            }
                                            return p;
                                        });
                                    setEntry({ ...entry, products });
                                }}
                            />
                                
                        )
                    }
                ]}
                rowKey={(record, index) => index}
                pagination={false}
            />
            <Button type="primary" onClick={() => setOpenCamera(true)}>Agregar foto</Button>
            <Flex direction="horizontal" style={{ marginTop: 20, overflowX: 'scroll' }}>
                {images && images.map((image, index) => (
                    <Space direction="vertical" style={{ marginRight: 10, paddingTop: 10 }}>
                        <Badge title="Eliminar" onClick={() => setImages(images.filter((_, i) => i !== index))} count="X" style={{ cursor: 'pointer' }}>
                            <img key={index} src={image} alt={`Foto ${index}`} style={{ width: 90, height: 160, objectFit: 'cover' }} />
                        </Badge>
                    </Space>
                ))}
            </Flex>
            <CameraModal open={openCamera} setOpen={setOpenCamera} onImageTook={setImages} />
        </Modal>
    );
}

export default NewInventoryEntry;
