const fetchIngredients = async () => {
    const ingredients = [{
        objectId: "1",
        name: "Sorgo",
        value: "Sorgo",
        basePresentation: "Kg",
        lastCost: 10,
    },
    {
        objectId: "2",
        name: "Girasol",
        value: "Girasol",
        basePresentation: "Kg",
        lastCost: 20,
    },
    {
        objectId: "3",
        name: "Maíz",
        value: "Maíz",
        basePresentation: "Kg",
        lastCost: 30,
    }];
    return ingredients;
}

const presentations =[{
    key: '1',
    name: 'Dasner Pet 10 Kg',
    content: '10',
    unit: 'Kg',
    minPrice: 100,
    package: [
        {
            name: 'Saco Dasner Pet 10 Kg',
            quantity: 1,
            unit: 'Pieza',
        },
        {
            name: 'Ribete Dasner Pet 10 Kg',
            quantity: 1,
            unit: 'Pieza',
        }
    ]
},
{
    key: '2',
    name: 'Dasner Pet 20 Kg',
    content: '20',
    unit: 'Kg',
    minPrice: 100,
    package: [
        {
            name: 'Saco Dasner Pet 20 Kg',
            quantity: 1,
            unit: 'Pieza',
        },
        {
            name: 'Ribete Dasner Pet 20 Kg',
            quantity: 1,
            unit: 'Pieza',
        }
    ]
    
}]

const fetchPackaging = async () => {
    const packaging = [{
        objectId: "1",
        name: "Saco",
        value: "Saco",
        basePresentation: "Pieza",
        lastCost: 10,
    },
    {
        objectId: "2",
        name: "Ribete",
        value: "Ribete",
        basePresentation: "Pieza",
        lastCost: 20,
    },
    {
        objectId: "3",
        name: "Caja",
        value: "Caja",
        basePresentation: "Pieza",
        lastCost: 30,
    }];
    return packaging;
}

export { fetchIngredients, presentations, fetchPackaging };