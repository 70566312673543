import React from 'react';
import { Row, Col, Card, Statistic, Steps, Table } from 'antd';

const pesoIngrdientes = 1000;
const pesoGrasaDigesta = 200;
const pesoTotal = pesoGrasaDigesta + pesoIngrdientes;


const dataSource = [
    { product: "Croquetas para perro", presentation: "5kg", packedUnities: 3, packedKgs: 15 },
];

const productShrinkageDataSource = [
    {product: "Croqueta para perros", quantity: 10, reason:"Exceso de ingredientes"},
    {product: "Croquerta para gatos", quantity: 45, reason:"Mala calidad"},
    {product: "Suplemento para gatos", quantity: 39, reason:"Producto contaminado"},
]

const CostalShrinkageDataSource = [
    {product: "Costal 10kg", quantity: 1, reason:"Defectos en el material"},
    {product: "Costal 20kg", quantity: 20, reason:"Se quemó el material"},
    {product: "Costal 5kg", quantity: 3, reason:"Material desaparecido"},
]

dataSource.forEach(item => {
    const weight = parseInt(item.presentation.replace('kg', ''), 10);
    item.total = item.unity * weight;
});


const columns = [
    {
        title: "Producto",
        dataIndex: "product",
        key: "product",
        render: (text) => <h3>{text}</h3>,
    },
    {
        title: "Presentación",
        dataIndex: "presentation",
        key: "presentation",
        render: (text) => <h3>{text}</h3>,
    },
    {
        title: "Kilos Envasados",
        dataIndex: "packedKgs",
        key: "packedKgs",
        render: (text) => <h3>{text}</h3>,
    },
    {
        title: "Unidades Envasadas",
        dataIndex: "packedUnities",
        key: "packedUnities",
        render: (text) => <h3>{text}</h3>,
    },
];

const shrinkageColumns = [
    {
        title: "Cantidad",
        dataIndex: "quantity",
        key: "quantity",
        render: (text) => <h3>{text}</h3>
    },
    {
        title: "Producto",
        dataIndex: "product",
        key: "product",
        render: (text) => <h3>{text}</h3>
    },
    {
        title: "Razón",
        dataIndex: "reason",
        key: "reason",
        render: (text) => <h3>{text}</h3>
    },
]

const PackingDetails = () => {
    return (
        <>
            <Row>
                <Col span={24}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: "0px" }}
                        style={{ padding: 20 }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={<h2>Producción</h2>}
                    >
                        <div className="ant-list-box table-responsive">
                            <Table
                                style={{ width: '100%' }}
                                dataSource={dataSource}
                                columns={columns}
                                rowKey={(record, index) => index}
                                pagination={true}
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col span={12} md={12}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: "0px" }}
                        style={{ padding: 20 }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={<h2>Merma de Costal</h2>}
                    >
                        <div className="ant-list-box table-responsive">
                            <Table
                                style={{ width: '100%' }}
                                dataSource={CostalShrinkageDataSource}
                                columns={shrinkageColumns}
                                rowKey={(record, index) => index}
                                pagination={true}
                            />
                        </div>
                    </Card>
                </Col>
                <Col span={12} md={12}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: "0px" }}
                        style={{ padding: 20 }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={<h2>Merma de Producto</h2>}
                    >
                        <div className="ant-list-box table-responsive">
                            <Table
                                style={{ width: '100%' }}
                                dataSource={productShrinkageDataSource}
                                columns={shrinkageColumns}
                                rowKey={(record, index) => index}
                                pagination={true}
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    )
}


export default PackingDetails;
