import React, { useEffect } from "react";
import { Button, Space, Typography, Modal, Form, Input, Select } from "antd";

const { Title } = Typography;

const NewIngredient = ({ open, setOpen }) => {
    const [form] = Form.useForm();
    const [renderId, setRenderId] = React.useState(0);

    useEffect(() => {
        form.setFieldsValue({
            name: "",
            basePresentation: "",
            presentations: []
        });
    }, [open]);

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            console.log(values);
            setOpen(false);
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <Modal
            title="Nuevo insumo"
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Form
                layout="vertical"
                form={form}
                name="basic"
            >
                <Form.Item
                    label="Nombre"
                    name="name"
                    rules={[{ required: true, message: 'Por favor ingrese el nombre del ingrediente' }]}
                >
                    <Input />
                </Form.Item>
                <Title level={5}>Unidad base</Title>
                <Form.Item name="basePresentation" rules={[{ required: true, message: 'Seleccione una presentación' }]}>
                    <Select placeholder="Presentación" onChange={() => {
                        form.setFieldsValue({ presentations: [{ presentation: '', price: '', quantity: '' }], basePresentation: form.getFieldValue('basePresentation') });
                    }} shouldUpdate>
                        <Select.Option value="Kg">Kg</Select.Option>
                        <Select.Option value="L">L</Select.Option>
                        <Select.Option value="Pieza">Pieza</Select.Option>
                    </Select>
                </Form.Item>
                {form.getFieldValue('basePresentation') && <Title level={5}>Presentaciones</Title>}
                <Form.List name="presentations" rules={[{ required: true, message: 'Ingrese al menos una presentación' }]}>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'presentation']}
                                        fieldKey={[fieldKey, 'presentation']}
                                        rules={[{ required: true, message: 'Seleccione una presentación' }]}
                                    >
                                    <Select placeholder="Presentación" style={{ width: 120 }} rules={[{ required: true, message: 'Seleccione una presentación' }]}>
                                        {form.getFieldValue('basePresentation') === 'Kg' &&
                                        <>
                                            <Select.Option value="Tonelada">Tonelada</Select.Option>
                                            <Select.Option value="Full">Full</Select.Option>
                                            <Select.Option value="Góndola">Góndola</Select.Option>
                                        </>}
                                        
                                        {form.getFieldValue('basePresentation') === 'L' &&
                                        <>
                                            <Select.Option value="Galón">Galón</Select.Option>
                                            <Select.Option value="Barril">Barril</Select.Option>
                                        </>}
                                        {form.getFieldValue('basePresentation') === 'Pieza' &&
                                        <>
                                            <Select.Option value="Caja">Caja</Select.Option>
                                            <Select.Option value="Paquete">Paquete</Select.Option>
                                            <Select.Option value="Bolsa">Bolsa</Select.Option>
                                        </>}
                                    </Select>
                                        
                                    </Form.Item>
                                    
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'quantity']}
                                        fieldKey={[fieldKey, 'quantity']}
                                        rules={[{ required: true, message: 'Ingrese la cantidad' }]}
                                    >
                                        <Input placeholder="Contenido" suffix={form.getFieldValue('basePresentation')} />
                                    </Form.Item>
                                    <Button onClick={() => remove(name)}>-</Button>
                                </Space>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                >
                                    Agregar presentación
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
}

export default NewIngredient;
