import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Space, Input, message, Select } from "antd";
import DebounceSelect from "../../../form/DebounceSelect";
import { fetchIngredients } from "../utils/proto";
import { PlusOutlined } from "@ant-design/icons";

const { Title } = Typography;

const IngredientList = ({ form, setRenderId }) => {
    const [ingredients, setIngredients] = useState([]);

    const unitCost = () => {
        const totalCost = ingredients.reduce((acc, ingredient) => acc + ingredient.total, 0);
        let unitCost = totalCost/form.getFieldValue("finalWeight");
        isNaN(unitCost) && (unitCost = 0);
        form.setFieldValue("unitCost", unitCost);
        return unitCost.toFixed(2);
    }

    const totalCost = () => {
        let totalCost = ingredients.reduce((acc, ingredient) => acc + ingredient.total, 0);
        isNaN(totalCost) && (totalCost = 0);
        return totalCost.toFixed(2);
    }


    useEffect(() => {
        form.getFieldValue("ingredients") && setIngredients(form.getFieldValue("ingredients"));
    }, [form]);
    

    const onIngredientSelected = (objectId, ingredient) => {
        console.log("ingredient", ingredient);
        const ingredients = form.getFieldValue("ingredients");
        if (ingredients.find((p) => p.objectId === ingredient.objectId)) {
            return;
        }
        ingredients.push({
            ...ingredient,
            quantity: 1,
            total: 0,
        });
        form.setFieldValue("ingredients", ingredients);
        setIngredients(ingredients);
        setRenderId((prev) => prev + 1);
    };

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <DebounceSelect
                fetchOptions={fetchIngredients}
                onChange={onIngredientSelected}
                placeholder="Buscar ingrediente"
                style={{ width: "100%" }}
                allowClear
                showSearch
            />
        <Table
            dataSource={ingredients}
            rowKey="objectId"
            pagination={false}
            scroll={{ x: true }}
        >
            <Table.Column
                title="Insumo"
                dataIndex="name"
                key="name"
            />
           
          
            <Table.Column
                title="Cantidad"
                dataIndex="quantity"
                key="quantity"
                render={(text, record) => (
                    <Input
                        type="number"
                        value={text}
                        onClick={(e) => e.target.select()}
                        style={{ width: 80 }}
                        sufix={record?.basePresentation}
                        onChange={(e) => {
                            const value = e.target.value;
                            const ingredients = form.getFieldValue("ingredients");
                            const ingredient = ingredients.find((p) => p.objectId === record.objectId);
                            ingredient.quantity = value;
                            ingredient.total = ingredient.lastCost * value;
                            form.setFieldValue("ingredients", ingredients);
                            setRenderId((prev) => prev + 1);
                        }}
                    />
                )}
            />
            
           
            <Table.Column
                title="Acción"
                key="action"
                render={(text, record) => (
                    <Button
                        type="danger"
                        onClick={() => {
                            const ingredients = form.getFieldValue("ingredients");
                            form.setFieldValue(
                                "ingredients",
                                ingredients.filter((p) => p.objectId !== record.objectId)
                            );
                            setIngredients(ingredients.filter((p) => p.objectId !== record.objectId));
                            setRenderId((prev) => prev + 1);
                        }}
                    >
                        Eliminar
                    </Button>
                )}
            />
        </Table>
        <Title level={5}>Costo total: ${totalCost()}</Title>
        <Title level={5}>Costo por Kg: ${unitCost()}</Title>
        </Space>
    );
}

export default IngredientList;
