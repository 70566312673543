import React from 'react';
import { Row, Col, Card, Statistic, Steps, Table } from 'antd';
import ProductionSummary from './Tabs/ProductionSummary';
import FormulaWeightDetail from './Tabs/FormulaWeightDetail';
import FatDigestWeightDetail from './Tabs/FatDigestWeightDetail';
import CroquetteWeightDetail from './Tabs/CroquetteWeightDetail';
import PackingDetails from './Tabs/PackingDetails';

const folio = 45678;

const steps = [
    {
        title: 'Resumen',
        content: <ProductionSummary />,
    },
    {
        title: 'Granos',
        content: <FormulaWeightDetail/>,
    },
    {
        title: 'Grasa y Digesta',
        content: <FatDigestWeightDetail/>,
    },
    {
        title: 'Croqueta',
        content: <CroquetteWeightDetail/>,
    },
    {
        title: 'Envasado',
        content: <PackingDetails/>,
    },
];

const ProductionDetails = () => {
    const [current, setCurrent] = React.useState(0);

    const onChange = current => {
        setCurrent(current);
    };

    return (
        <Col>
            <h1 style={{ fontSize: '24px', fontWeight: 'bold' }}>Detalles de Producción</h1>
            <h2>Folio de Producción: {folio}</h2>
            <Row justify="center" style={{ marginBottom: '20px' }}>
                <Col span={14}>
                    <Steps
                        current={current}
                        onChange={onChange}
                        size="small"
                        direction="horizontal"
                    >
                        {steps.map((step, index) => (
                            <Steps.Step key={index} title={step.title} />
                        ))}
                    </Steps>
                </Col>
            </Row>
            <div className="steps-content" style={{ marginTop: '20px', textAlign: 'center' }}>
                {steps[current].content}
            </div>
        </Col>
    );
};

export default ProductionDetails;
