import React, { useState } from "react";
import { Card, Col, Row, Table, Typography, FloatButton, Progress } from "antd";
import ReactApexChart from "react-apexcharts";
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import NewQuote from "./components/NewQuote";

const { Title } = Typography;

const SalesList = () => {
  const [newQuoteModal, setNewQuoteModal] = useState(false);
  const dataSource = [
    {
      date: '10/06/2024',
      folio: 'D0010',
      client: 'Ferretería la herradura',
      supplier: "Fernando Lopez",
      products: '5 costales perro 5kg',
      total: 8000,
      volume: 30, // Add this field to the data source to show the volume of the deliveries:
      deliveries: [
        {
          volume: 10,
        },
        {
          volume: 10,
        }
      ]
    },
    {
      date: '08/06/2024',
      folio: 'D0009',
      client: 'Petco',
      supplier: "Alejando Ramirez",
      products: '10 costales perro 25kg, 5 costales gato 20kg',
      total: 20000,
      payments: [
        {
          amount: 10000,
        },
        {
          amount: 10000,
        }
      ]
    },
    {
      date: '07/06/2024',
      folio: 'D0008',
      client: 'Comercial Cabrera',
      supplier: "Mariana Aldrete",
      products: '10 costales perro 5kg, 3 costales perro 5kg',
      total: 12000,
    },
    {
      date: '04/06/2024',
      folio: 'D0007',
      client: 'Tiendita el Sol',
      supplier: "Emanuel Martín",
      products: '5 costales perro 5kg, 2 costales gato 20kg',
      total: 9000,
    },
    {
      date: '01/06/2024',
      folio: 'D0006',
      client: 'Distribuidora García',
      supplier: "Jaime Aldrete",
      products: "2 costales perro 25kg, 1 costal gato 20kg, 10 costales perro 5kg",
      total: 15000,
    },
  ];

  // Calcular la suma total de los totales
  const totalSales = dataSource.reduce((acc, curr) => acc + curr.total, 0);

  // Preparar datos para el gráfico
  const chartData = {
    categories: dataSource.map(item => item.date),
    series: [
      {
        name: "Ventas en pesos",
        data: dataSource.map(item => item.total),
      },
      {
        name: "Kilos",
        data: [30, 90, 40, 140, 290], // Datos de ejemplo para los kilos, ajustar según sea necesario
      },
    ],
  };

  const options = {
    chart: {
      width: "100%",
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["#1890FF", "#B37FEB"],
      width: 3,
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#1890FF',
        },
        labels: {
          style: {
            colors: '#1890FF',
          },
          formatter: function (val) {
            return `$${val.toLocaleString()}`;
          }
        },
        title: {
          text: 'Ventas en $',
          style: {
            color: '#1890FF',
          }
        },
        tooltip: {
          enabled: true,
          formatter: function (val) {
            return `$${val.toLocaleString()}`;
          }
        }
      },
      {
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#B37FEB',
        },
        labels: {
          style: {
            colors: '#B37FEB',
          },
          formatter: function (val) {
            return `${val} KG`;
          }
        },
        title: {
          text: 'KGs',
          style: {
            color: '#B37FEB',
          }
        },
        tooltip: {
          enabled: true,
          formatter: function (val) {
            return `${val} KG`;
          }
        }
      }
    ],
    xaxis: {
      categories: chartData.categories,
      labels: {
        style: {
          fontSize: "14px",
          fontWeight: 600,
          colors: ["#8c8c8c"],
        },
      },
    },
    grid: {
      show: true,
      borderColor: "rgba(0, 0, 0, .2)",
      strokeDashArray: 6,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        colors: undefined,
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    tooltip: {
      y: {
        formatter: function (val, opts) {
          const seriesName = opts.seriesIndex === 0 ? "Ventas en $: " : "KGs: ";
          return opts.seriesIndex === 0 ? `$${val.toLocaleString()}` : `${val.toLocaleString()}kg`
        },
      },
    },
  };

  const columns = [
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
      render: (text) => <h3>{text}</h3>,
    },
    {
      title: "Folio",
      dataIndex: "folio",
      key: "folio",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Cliente",
      dataIndex: "client",
      key: "client",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendedor",
      dataIndex: "supplier",
      key: "supplier",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Productos",
      dataIndex: "products",
      key: "products",
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Entregas',
      dataIndex: 'deliveries',
      key: 'deliveries',
      render: (text, entry) => {
        let deliveredVolume = 0;
        if (entry.deliveries) {
          deliveredVolume = entry.deliveries.reduce((acc, curr) => acc + curr.volume, 0);
        }
        if (deliveredVolume === 0) {
          return 'Sin entregas';
        }
        // Percentage to 2 decimal places
        const percentage = ((deliveredVolume / entry.volume) * 100).toFixed(0);
        return <Progress percent={percentage}  style={{ width: 100 }} format={() => deliveredVolume < entry.volume ? percentage + '%' : 'Entregado'} />
      }
    },
    {
      title: 'Pagos',
      dataIndex: 'payments',
      key: 'payments',
      render: (text, entry) => {
        let paidAmount = 0;
        if (entry.payments) {
          paidAmount = entry.payments.reduce((acc, curr) => acc + curr.amount, 0);
        }
        // Percentage to 2 decimal places
        const percentage = ((paidAmount / entry.total) * 100).toFixed(2);
        if (paidAmount === 0) {
          return 'Sin pagos';
        }
        return <Progress percent={percentage} style={{ width: 100 }} format={() => paidAmount < entry.total ? `$${(entry.total - paidAmount).toLocaleString()}` : 'Pagado'} />
      }
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text) => <h3>${text.toLocaleString()}</h3>,
    },
  ];

  return (
    <div className="layout-content">
      <NewQuote open={newQuoteModal} setOpen={setNewQuoteModal} />
      <FloatButton style={{ right: 20, bottom: 20 }} tooltip="Nueva venta" icon=<PlusOutlined /> onClick={() => setNewQuoteModal(true)} />
      <Row>
        <Col span={24}>
          <Card
            style={{ padding: 20 }}
            bordered={false}
            bodyStyle={{ padding: "0px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3 style={{fontSize:"25px"}}>Estadística de Ventas</h3>}
          >
            <div className="ant-list-box table-responsive">
              <div className="linechart">
                <div></div>
                <div className="sales">
                  <ul>
                    <li><MinusOutlined /> Kilos</li>
                    <li><MinusOutlined /> Venta en $</li>
                  </ul>
                </div>
              </div>

              <ReactApexChart
                className="full-width"
                options={options}
                series={chartData.series}
                type="area"
                height={350}
                width={"100%"}
              />
            </div>
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col lg={24} className="zindex">
          <h2 className="p-15 h2">Ventas</h2>

          <Card
            bordered={false}
            bodyStyle={{ padding: "0px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3 style={{fontSize:"25px"}}>Historial de Ventas</h3>}
          >
            <div className="ant-list-box table-responsive">
              <Table
                dataSource={dataSource}
                columns={columns} // Usar las columnas con la suma total
                rowKey={(record, index) => index}
                pagination={true}
                footer={() => (
                  <div style={{ textAlign: "right", fontSize:"20px" }}>
                    <strong>Total:</strong> ${totalSales.toLocaleString()}
                  </div>
                )}
              />
            </div>
          </Card>
        </Col>
      </Row>
      
    </div>
  );
};

export default SalesList;
