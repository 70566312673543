import React from 'react';
import { Row, Col, Card, Statistic, Steps, Table } from 'antd';

var total = 0
const polvosFinos = 30
const colors = {
    "red": 16,
    "green": 24,
    "yellow": 60,
}

const dataSource = [
    { ingredient: "Proteína", quantity: "40kg" },
    { ingredient: "Grasas", quantity: "10kg" },
    { ingredient: "Vitaminas", quantity: "18kg" },
];

const shrinkageColumns = [
    {
        title: "Cantidad",
        dataIndex: "quantity",
        key: "quantity",
        render: (text) => <h3>{text}</h3>
    },
    {
        title: "Producto",
        dataIndex: "product",
        key: "product",
        render: (text) => <h3>{text}</h3>
    },
    {
        title: "Motivo",
        dataIndex: "reason",
        key: "reason",
        render: (text) => <h3>{text}</h3>
    },
]

const productShrinkageDataSource = [
    {product: "Croqueta para perros", quantity: 10, reason:"Exceso de ingredientes"},
    {product: "Croquerta para gatos", quantity: 45, reason:"Mala calidad"},
    {product: "Suplemento para gatos", quantity: 39, reason:"Producto contaminado"},
]

const columns = [
    {
        title: "Ingrediente",
        dataIndex: "ingredient",
        key: "ingrediente",
        render: (text) => <h3>{text}</h3>,
    },
    {
        title: "Cantidad",
        dataIndex: "quantity",
        key: "quantity",
        render: (text) => <h3>{text}</h3>,
    },
];

dataSource.forEach(item => {
    const quantity = parseInt(item.quantity.replace('kg', ''), 10);
    total = total + quantity;
});

const CroquetteWeightDetail = () => {
    return (
        <Col>
        <Row>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ margin: '10px', width: "100%" }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={
                            <Statistic
                                title="Peso de Croqueta"
                                value={total}
                                suffix="KG"
                                style={{ fontSize: '20px' }}
                            />
                        }
                    />
                </Row>
                <Row>
                    <Col>
                    <Card title="Conteo de Colores" bordered={false} style={{ width: 300 }}>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Statistic
                                    title="Rojo"
                                    value={colors["red"]}
                                    suffix="%"
                                    valueStyle={{ color: 'red', fontSize: '20px' }}
                                />
                            </Col>
                            <Col span={8}>
                                <Statistic
                                    title="Verde"
                                    value={colors["green"]}
                                    suffix="%"
                                    valueStyle={{ color: 'green', fontSize: '20px' }}
                                />
                            </Col>
                            <Col span={8}>
                                <Statistic
                                    title="Amarillo"
                                    value={colors["yellow"]}
                                    suffix="%"
                                    valueStyle={{ color: 'gold', fontSize: '20px' }}
                                />
                            </Col>
                        </Row>
                    </Card>
                    </Col>
                </Row>
                <Row>
                <Card
                        bordered={false}
                        bodyStyle={{ padding: "0px"}}
                        style={{ padding: 20, marginRight: 10 }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={<h2>Merma</h2>}
                    >
                        <div className="ant-list-box table-responsive">
                            <Table
                                style={{ width: '100%' }}
                                dataSource={productShrinkageDataSource}
                                columns={shrinkageColumns}
                                rowKey={(record, index) => index}
                                pagination={true}
                            />
                        </div>
                    </Card>
                    </Row>  
        </Col>
    )
}

export default CroquetteWeightDetail;