import React from "react";
import { Card, Col, Row, Table, Typography, Statistic, FloatButton } from "antd";
import ReactApexChart from "react-apexcharts";
import NewProduct from "./components/NewProduct";

const { Title } = Typography;

const ProductInventory = () => {
  const [newProductModal, setNewProductModal] = React.useState(false);
  const dataSource = [
    {
      date: '10/06/2024',
      product: 'Croqueta Perro 10 kg',
      quantity: 5000,
      unitCost: "$35",
      unitPrice: "$140",
      TotalValue: 700000,
      ProductionLastMonth: 3000,
      SalesLastMonth: "$120,000",
      measure: 'Costales',
    },
    {
      date: '06/02/2024',
      product: 'Croqueta Perro 15 kg',
      quantity: 8000,
      unitCost: "$60",
      unitPrice: "$180",
      TotalValue: 830000,
      ProductionLastMonth: 12000,
      SalesLastMonth: "$320,000",
      measure: 'Costales',
    },
    {
      date: '02/01/2024',
      product: 'Croqueta para Gato 10kg',
      quantity: 3000,
      unitCost: "$20",
      unitPrice: "$80",
      TotalValue: 23000,
      ProductionLastMonth: 1500,
      SalesLastMonth: "$60,000",
      measure: 'Costales',
    },
    {
      date: '01/01/2024',
      product: 'Croqueta para Gato 15kg',
      quantity: 8000,
      unitCost: "$30",
      unitPrice: "$160",
      TotalValue: 29000,
      ProductionLastMonth: 4000,
      SalesLastMonth: "$24,000",
      measure: 'Botes',
    },
  ];

  // Calculando la suma de TotalValue de todos los elementos en dataSource
  const totalValueSum = dataSource.reduce((acc, item) => acc + item.TotalValue, 0);
  const totalVolumeSum = dataSource.reduce((acc, item) => acc + item.quantity, 0);

  // Definición de columnas para la tabla de productos
  const columns = [
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
      render: (text) => <h3>{text}</h3>,
    },
    {
      title: "Producto",
      dataIndex: "product",
      key: "product",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Medida Cantidad",
      dataIndex: "measure",
      key: "measure",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Precio Unitario",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Costo Unitario",
      dataIndex: "unitCost",
      key: "unitCost",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Valor Total",
      dataIndex: "TotalValue",
      key: "TotalValue",
      render: (text) => <p>{`$ ${text.toLocaleString()}`}</p>,
    },
    {
      title: "Producción Último Mes",
      dataIndex: "ProductionLastMonth",
      key: "ProductionLastMonth",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Ventas Último Mes",
      dataIndex: "SalesLastMonth",
      key: "SalesLastMonth",
      render: (text) => <p>{text}</p>,
    },
  ];

  // Preparar datos para el gráfico de líneas
  const chartData = {
    series: [
      {
        name: "Valor Total",
        data: dataSource.map(item => ({ x: item.date, y: item.TotalValue })),
      },
      {
        name: "Volumen Total",
        data: dataSource.map(item => ({ x: item.date, y: item.quantity })),
      },
    ],
    options: {
      chart: {
        type: 'line',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        categories: dataSource.map(item => item.date),
      },
      yaxis: [
        {
          title: {
            text: 'Valor ($)',
          },
        },
        {
          opposite: true,
          title: {
            text: 'Volumen (Unidades)',
          },
        },
      ],
      tooltip: {
        x: {
          format: 'dd/MM/yyyy',
        },
        y: {
          formatter: function (val, opts) {
            try {
              return opts.seriesIndex === 0 ? `$ ${val.toLocaleString()}` : `${val} unidades`;  
            } catch (error) {
              return `${val} unidades`;  
            }
          },
        },
      },
    },
  };

  return (
    <div className="layout-content">
      <FloatButton onClick={() => setNewProductModal(true)} />
      <NewProduct open={newProductModal} setOpen={setNewProductModal} />
      <Row gutter={[24, 0]}>
        <Col lg={24} className="zindex">
          <h2 className="p-15 h2">Inventario de productos</h2>

          <Card
            bordered={false}
            bodyStyle={{ padding: "0px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Productos</h3>}
          >
            <div className="ant-list-box table-responsive">
              <Table
                dataSource={dataSource}
                columns={columns}
                rowKey={(record, index) => index}
                pagination={true}
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col lg={12} md={24} className="zindex">
          <Card
            bordered={false}
            bodyStyle={{ padding: "20px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Valor en $</h3>}
          >
            <Statistic title="Total" value={totalValueSum} prefix="$" style={{ fontSize: '20px' }} />
          </Card>
        </Col>
        <Col lg={12} md={24} className="zindex">
          <Card
            bordered={false}
            bodyStyle={{ padding: "20px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Volumen</h3>}
          >
            <Statistic title="Total" value={totalVolumeSum} suffix="unidades" style={{ fontSize: '20px' }} />
          </Card>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Card
            bordered={false}
            bodyStyle={{ padding: "20px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Estadísticas de Inventario</h3>}
          >
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="line"
              height={350}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ProductInventory;
