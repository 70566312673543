import React from 'react';
import { Row, Col, Card, Statistic, Steps, Table } from 'antd';

const dataSource = [
    { ingredient: "Grasa 30/70", quantity: "40kg" },
    { ingredient: "Digesta A20", quantity: "10kg" },
];

const columns = [
    {
        title: "Ingrediente",
        dataIndex: "ingredient",
        key: "ingrediente",
        render: (text) => <h3>{text}</h3>,
    },
    {
        title: "Cantidad",
        dataIndex: "quantity",
        key: "quantity",
        render: (text) => <h3>{text}</h3>,
    },
];


const FatDigestWeightDetail = () => {
    return (
        <>
            <Col span={24} md={24}>
                <Card
                    bordered={false}
                    bodyStyle={{ padding: "0px" }}
                    style={{ padding: 20, width: "100%" }}
                    className="criclebox cardbody mb-24 header-solid"
                    title={<h2>Detalles de Peso de Fórmula</h2>}
                >
                    <div className="ant-list-box table-responsive">
                        <Table
                            style={{ width: '100%' }}
                            dataSource={dataSource}
                            columns={columns}
                            rowKey={(record, index) => index}
                            pagination={true}
                        />
                    </div>
                </Card>
            </Col>
        </>
    )
}

export default FatDigestWeightDetail;