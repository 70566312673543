import React, { useEffect } from "react";
import { Button, Space, Typography, Modal, Form, Input, Select } from "antd";
import IngredientList from "./IngredientList";
import PresentationList from "./PresentationList";
import NewPresentation from "./NewPresentation";
const { Title } = Typography;

const NewProduct = ({ open, setOpen }) => {
    const [form] = Form.useForm();
    const [renderId, setRenderId] = React.useState(0);
    const [newPresentation, setNewPresentation] = React.useState(false);

    useEffect(() => {
        form.setFieldsValue({
            name: "",
            basePresentation: "",
            presentations: [],
            ingredients: [],
        });
    }, [open]);

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            console.log(values);
            setOpen(false);
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <>
            <NewPresentation open={newPresentation} setOpen={setNewPresentation} />
        <Modal
            title="Nuevo producto"
            open={open}
            width={800}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Form
                layout="vertical"
                form={form}
                name="basic"
            >
                <Form.Item
                    label="Nombre"
                    name="name"
                    rules={[{ required: true, message: 'Por favor ingrese el nombre del ingrediente' }]}
                >
                    <Input />
                </Form.Item>
                <Title level={5}>Ingredientes</Title>

                <Form.Item
                    label="Peso producto terminado"
                    name="finalWeight"
                    rules={[{ required: true, message: 'Por favor indique el peso del producto terminado' }]}
                >
                    <Input type="number" suffix="Kg" />
                </Form.Item>
               
                
                        <Form.Item label="Insumos" name={['ingredients']} rules={[{ required: true, message: 'Seleccione al menos un insumo' }]}>
                            <IngredientList form={form} setRenderId={setRenderId} />
                            <Title level={5}>Presentaciones</Title>
                        </Form.Item>
                        <Form.Item label="Presentaciones" name={['presentations']} rules={[{ required: true, message: 'Seleccione al menos una presentación' }]}>
                            <PresentationList form={form} setRenderId={setRenderId} />
                        </Form.Item>
            </Form>
        </Modal>
        </>
    );
}

export default NewProduct;