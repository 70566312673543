import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Space, Input, message, Select } from "antd";
import DebounceSelect from "../../../form/DebounceSelect";
import { fetchProducts } from "../utils/proto";
import { PlusOutlined } from "@ant-design/icons";

const { Title } = Typography;

const ProductList = ({ form, setRenderId }) => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        form.getFieldValue("products") && setProducts(form.getFieldValue("products"));
    }, [form]);
    

    const onProductSelected = (objectId, product) => {
        console.log("product", product);
        const products = form.getFieldValue("products");
        if (products.find((p) => p.objectId === product.objectId)) {
            return;
        }
        products.push({
            ...product,
            quantity: 1,
            total: 0,
        });
        form.setFieldValue("products", products);
        setProducts(products);
        setRenderId((prev) => prev + 1);
    };

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <DebounceSelect
                fetchOptions={fetchProducts}
                onChange={onProductSelected}
                placeholder="Buscar producto"
                style={{ width: "100%" }}
                allowClear
                showSearch
            />
        <Table
            dataSource={products}
            rowKey="objectId"
            pagination={false}
            scroll={{ x: true }}
        >
            <Table.Column
                title="Producto"
                dataIndex="name"
                key="name"
            />
           
           <Table.Column
                title="Unidad"
                dataIndex="presentation"
                key="presentation"
                render={(text, record) => (
                    <Select
                        style={{ width: 120 }}
                        value={text}
                        onChange={(value) => {
                            let products = form.getFieldValue("products");
                            const product = products.find((p) => p.objectId === record.objectId);
                            console.log("product", product);
                            product.presentation = value;
                            product.cost = 0;
                            product.lastCost = product.costs?.find((c) => c.unit === value)?.cost;
                            // product.total = product.lastCost * product.quantity;
                            product.total = 0;
                            form.setFieldValue("products", products);
                            setRenderId((prev) => prev + 1);
                        }}
                        options={record?.units?.map((p) => ({ value: p, label: p }))}
                    />
            )}
            />
            <Table.Column
                title="Cantidad"
                dataIndex="quantity"
                key="quantity"
                render={(text, record) => (
                    <Input
                        type="number"
                        value={text}
                        onClick={(e) => e.target.select()}
                        style={{ width: 80 }}
                        onChange={(e) => {
                            const value = e.target.value;
                            const products = form.getFieldValue("products");
                            const product = products.find((p) => p.objectId === record.objectId);
                            product.quantity = value;
                            product.total = product.lastCost * value;
                            form.setFieldValue("products", products);
                            setRenderId((prev) => prev + 1);
                        }}
                    />
                )}
            />
            
            
             <Table.Column
                title="Último costo"
                dataIndex="lastCost"
                key="lastCost"
                render={(lastCost, product) => <p>${lastCost?.toLocaleString()}</p>}
            />
            <Table.Column
                title="Costo"
                dataIndex="cost"
                key="cost"
                render={(text, record) => (
                    <Input
                        type="number"
                        value={text}
                        onClick={(e) => e.target.select()}
                        style={{ width: 100 }}
                        onChange={(e) => {
                            const value = e.target.value;
                            const products = form.getFieldValue("products");
                            const product = products.find((p) => p.objectId === record.objectId);
                            product.cost = value;
                            product.total = value * product.quantity;
                            form.setFieldValue("products", products);
                            setRenderId((prev) => prev + 1);
                        }}
                    />
                )}
            />
            <Table.Column
                title="Total"
                dataIndex="total"
                key="total"
                render={(text) => <p>${text?.toLocaleString()}</p>}
            />
            <Table.Column
                title="Acción"
                key="action"
                render={(text, record) => (
                    <Button
                        type="danger"
                        onClick={() => {
                            const products = form.getFieldValue("products");
                            form.setFieldValue(
                                "products",
                                products.filter((p) => p.objectId !== record.objectId)
                            );
                            setProducts(products.filter((p) => p.objectId !== record.objectId));
                            setRenderId((prev) => prev + 1);
                        }}
                    >
                        Eliminar
                    </Button>
                )}
            />
        </Table>
        </Space>
    );
}

export default ProductList;
