const fetchProviders = async (search) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve([
                {
                    objectId: "1",
                    name: "Ferretería la herradura",
                    value: "Ferretería la herradura",
                },
                {
                    objectId: "2",
                    name: "Petco",
                    value: "Petco",
                },
                {
                    objectId: "3",
                    name: "Comercial Cabrera",
                    value: "Comercial Cabrera",
                },
                {
                    objectId: "4",
                    name: "Tiendita el Sol",
                    value: "Tiendita el Sol",
                },
                {
                    objectId: "5",
                    name: "Distribuidora García",
                    value: "Distribuidora",
                },
            ]);
        }, 1000);
    });
};

const fetchProducts = async (search) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve([
                {
                    objectId: "1",
                    name: "Sorgo",
                    value: "Sorgo",
                    costs: [{
                        unit: 'Tonelada',
                        cost: 1500
                    }, {
                        unit: 'Full',
                        cost: 200000
                    }],
                    units: ['Tonelada', 'Full']
                },
                {
                    objectId: "2",
                    name: "Girasol",
                    value: "Girasol",
                    costs: [{
                        unit: 'Tonelada',
                        cost: 1800,
                    }, {
                        unit: 'Full',
                        cost: 250000
                    }, {
                        unit: 'Cama baja',
                        cost: 300000
                    }],
                    units: ['Tonelada', 'Full', 'Cama baja']
                },
                {
                    objectId: "3",
                    name: "Maíz",
                    value: "Maíz",
                    costs: [{
                        unit: 'Tonelada',
                        cost: 1400
                    }, {
                        unit: 'Full',
                        cost: 190000
                    }],
                    units: ['Tonelada', 'Full']
                },
            ]);
        }, 1000);
    });
}

export { fetchProviders, fetchProducts };