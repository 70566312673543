import moment from 'moment';

const fetchClient = async (search) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve([
                {
                    objectId: "1",
                    name: "Ferretería la herradura",
                    value: "Ferretería la herradura",
                },
                {
                    objectId: "2",
                    name: "Petco",
                    value: "Petco",
                },
                {
                    objectId: "3",
                    name: "Comercial Cabrera",
                    value: "Comercial Cabrera",
                },
                {
                    objectId: "4",
                    name: "Tiendita el Sol",
                    value: "Tiendita el Sol",
                },
                {
                    objectId: "5",
                    name: "Distribuidora García",
                    value: "Distribuidora",
                },
            ]);
        }, 1000);
    });
};

const fetchProducts = async (search) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve([
                {
                    objectId: "1",
                    name: "Dasner Perro Adulto",
                    value: "Dasner Perro Adulto",
                    presentations: [
                        {
                            objectId: "1",
                            name: "Costal 20kg",
                            value: "Costal 20kg",
                            minPrice: 1000,
                            lastClientPrice: 1200,
                            price: 1200,
                            inventory: 100,
                            lastPurchase: moment('2024-05-08', 'YYYY-MM-DD')
                        },
                        {
                            objectId: "2",
                            name: "Costal 10kg",
                            value: "Costal 10kg",
                            minPrice: 500,
                            lastClientPrice: 600,
                            price: 600,
                            inventory: 50,
                            lastPurchase: moment('2024-05-29', 'YYYY-MM-DD')
                        },
                    ],
                },
                {
                    objectId: "2",
                    name: "Dasner Perro Cachorro",
                    value: "Dasner Perro Cachorro",
                    presentations: [
                        {
                            objectId: "1",
                            name: "Costal 25kg",
                            value: "Costal 25kg",
                            minPrice: 1200,
                            lastClientPrice: 1400,
                            price: 1400,
                            inventory: 80,
                            lastPurchase: moment('2024-05-08', 'YYYY-MM-DD')
                        },
                        {
                            objectId: "2",
                            name: "Costal 15kg",
                            value: "Costal 15kg",
                            minPrice: 800,
                            lastClientPrice: 900,
                            price: 900,
                            inventory: 40,
                            lastPurchase: moment('2024-05-29', 'YYYY-MM-DD')
                        },
                    ],
                },
                {
                    objectId: "3",
                    name: "Dasner Gato",
                    value: "Dasner Gato",
                    presentations: [
                        {
                            objectId: "1",
                            name: "Costal 20kg",
                            value: "Costal 20kg",
                            minPrice: 800,
                            lastClientPrice: 900,
                            price: 900,
                            inventory: 70,
                            lastPurchase: moment('2024-05-08', 'YYYY-MM-DD')
                        },
                        {
                            objectId: "2",
                            name: "Costal 10kg",
                            value: "Costal 10kg",
                            minPrice: 400,
                            lastClientPrice: 500,
                            price: 500,
                            inventory: 30,
                            lastPurchase: moment('2024-05-29', 'YYYY-MM-DD')
                        },
                    ],
                },
            ]);
        }, 1000);
    });
}

export { fetchClient, fetchProducts };