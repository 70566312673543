import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Space, Input, message, Select } from "antd";
import DebounceSelect from "../../../form/DebounceSelect";
import { fetchPackaging } from "../utils/proto";
import { PlusOutlined } from "@ant-design/icons";

const { Title } = Typography;

const PackagingList = ({ form, parentForm }) => {
    const [items, setItems] = useState([])
    const [renderId, setRenderId] = useState(0);

    useEffect(() => {
        form.getFieldValue("items") && setItems(form.getFieldValue("items"));
    }, [form]);

    const totalCost = () => {
        const items = form.getFieldValue("items");
        let totalCost = items.reduce((acc, item) => acc + item.total, 0);
        totalCost = totalCost + parentForm.getFieldValue("unitCost") * form.getFieldValue("quantity");
        isNaN(totalCost) && (totalCost = 0);
        return totalCost.toFixed(2);
    }
    

    const onItemSelected = (objectId, item) => {
        console.log("item", item);
        const items = form.getFieldValue("items");
        if (items.find((p) => p.objectId === item.objectId)) {
            return;
        }
        items.push({
            ...item,
            quantity: 1,
            total: item.lastCost,
        });
        form.setFieldValue("items", items);
        setItems(items);
        setRenderId((prev) => prev + 1);
    };

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <DebounceSelect
                fetchOptions={fetchPackaging}
                onChange={onItemSelected}
                placeholder="Buscar insumo"
                style={{ width: "100%" }}
                allowClear
                showSearch
            />
        <Table
            dataSource={items}
            rowKey="objectId"
            pagination={false}
            scroll={{ x: true }}
        >
            <Table.Column
                title="Insumo"
                dataIndex="name"
                key="name"
            />
           
          
            <Table.Column
                title="Cantidad"
                dataIndex="quantity"
                key="quantity"
                render={(text, record) => (
                    <Input
                        type="number"
                        value={text}
                        onClick={(e) => e.target.select()}
                        style={{ width: 80 }}
                        sufix={record.basePresentation}
                        onChange={(e) => {
                            console.log("e", record);
                            const value = e.target.value;
                            const items = form.getFieldValue("items");
                            let item = items.find((p) => p.objectId === record.objectId);
                            item.quantity = value;
                            item.total = item.lastCost * value;
                            console.log("item", item);
                            const newItems = items.map((p) => (p.objectId === record.objectId ? item : p));
                            form.setFieldValue("items", newItems);
                            setRenderId((prev) => prev + 1);
                        }}
                    />
                )}
            />
            
           
            <Table.Column
                title="Acción"
                key="action"
                render={(text, record) => (
                    <Button
                        type="danger"
                        onClick={() => {
                            const items = form.getFieldValue("items");
                            form.setFieldValue(
                                "items",
                                items.filter((p) => p.objectId !== record.objectId)
                            );
                            setItems(items.filter((p) => p.objectId !== record.objectId));
                            setRenderId((prev) => prev + 1);
                        }}
                    >
                        Eliminar
                    </Button>
                )}
            />
        </Table>
        <Title level={5}>Costo total: ${totalCost()}</Title>
        </Space>
    );
}

export default PackagingList;
