import React, { useEffect, useState } from "react";
import { Form, Typography, Button, Space, Input, message, Modal } from "antd";
import PackagingList from "./PackagingList";

const { Title } = Typography;

const NewPresentation = ({ parentForm, open, setOpen }) => {
    const [presentations, setPresentations] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        form.getFieldValue("presentations") && setPresentations(form.getFieldValue("presentations"));
        form.setFieldsValue({
            items: [],
        });
    }, [form]);


    const onPresentationSelected = () => {
        const presentations = form.getFieldValue("presentations");
        presentations.push({
            name: "",
            price: 0,
            quantity: 0,
            total: 0,
        });
        form.setFieldValue("presentations", presentations);
        setPresentations(presentations);
    };
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            console.log(values);
            setOpen(false);
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
      <Modal 
        title="Nueva presentación"
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
            <Button key="back" onClick={() => setOpen(false)}>
                Cancelar
            </Button>,
            <Button key="submit" type="primary" onClick={handleSubmit}>
                Guardar
            </Button>,
        ]}
        >
        <Form
            layout="vertical"
            form={form}
            name="basic"
        >
            <Form.Item
                label="Nombre"
                name="name"
                rules={[{ required: true, message: 'Por favor ingrese el nombre de la presentación' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Contenido"
                name="quantity"
                rules={[{ required: true, message: 'Por favor ingrese el contenido de la presentación' }]}
                shouldUpdate
            >
                <Input suffix="kg" />
            </Form.Item>
            <Form.Item
                label="Precio mínimo"
                name="minimumPrice"
                rules={[{ required: true, message: 'Por favor ingrese el precio de la presentación' }]}
            >
                <Input />
            </Form.Item>
            <Title level={5}>Empaque</Title>

            <PackagingList form={form} parentForm={parentForm} />
        </Form>
        </Modal>
    );
}

export default NewPresentation;