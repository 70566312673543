import React from 'react';
import { Row, Col, Card, Statistic, Steps, Table } from 'antd';

const pesoIngrdientes = 1000;
const pesoGrasaDigesta = 200;
const pesoTotal = pesoGrasaDigesta + pesoIngrdientes;


const dataSource = [
    { presentation: "5kg", unity: 3 },
    { presentation: "10kg", unity: 19 },
    { presentation: "20kg", unity: 56 },
];

const productShrinkageDataSource = [
    {product: "Croqueta para perros", quantity: 10, reason:"Exceso de ingredientes"},
    {product: "Croquerta para gatos", quantity: 45, reason:"Mala calidad"},
    {product: "Suplemento para gatos", quantity: 39, reason:"Producto contaminado"},
]

const supplyShrinkageDataSource = [
    {product: "Costal 10kg", quantity: 1, reason:"Defectos en el material"},
    {product: "Costal 20kg", quantity: 20, reason:"Se quemó el material"},
    {product: "Costal 5kg", quantity: 3, reason:"Material desaparecido"},
]

dataSource.forEach(item => {
    const weight = parseInt(item.presentation.replace('kg', ''), 10);
    item.total = item.unity * weight;
});


const columns = [
    {
        title: "Presentación",
        dataIndex: "presentation",
        key: "presentation",
        render: (text) => <h3>{text}</h3>,
    },
    {
        title: "Unidad",
        dataIndex: "unity",
        key: "unity",
        render: (text) => <h3>{text}</h3>,
    },
    {
        title: "Total (KG)",
        dataIndex: "total",
        key: "total",
        render: (text) => <h3>{text}</h3>,
    },
];

const shrinkageColumns = [
    {
        title: "Cantidad",
        dataIndex: "quantity",
        key: "quantity",
        render: (text) => <h3>{text}</h3>
    },
    {
        title: "Producto",
        dataIndex: "product",
        key: "product",
        render: (text) => <h3>{text}</h3>
    },
    {
        title: "Motivo",
        dataIndex: "reason",
        key: "reason",
        render: (text) => <h3>{text}</h3>
    },
]

const ProductionSummary = () => {
    return (
        <>
            <Row gutter={16}>
                <Col span={8}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ margin: '10px' }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={
                            <Statistic
                                title="Peso en Ingredientes"
                                value={pesoIngrdientes}
                                suffix="KG"
                                style={{ fontSize: '20px' }}
                            />
                        }
                    />
                </Col>
                <Col span={8}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ margin: '10px' }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={
                            <Statistic
                                title="Peso Grasa y Digesta"
                                value={pesoGrasaDigesta}
                                suffix="KG"
                                style={{ fontSize: '20px' }}
                            />
                        }
                    />
                </Col>
                <Col span={8}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ margin: '10px' }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={
                            <Statistic
                                title="Peso Total"
                                value={pesoTotal}
                                suffix="KG"
                                style={{ fontSize: '20px' }}
                            />
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: "0px"}}
                        style={{ padding: 20 }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={<h2>Envasado</h2>}
                    >
                        <div className="ant-list-box table-responsive">
                            <Table
                                style={{ width: '100%' }}
                                dataSource={dataSource}
                                columns={columns}
                                rowKey={(record, index) => index}
                                pagination={true}
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col span={12} md={12}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: "0px"}}
                        style={{ padding: 20, marginRight: 10 }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={<h2>Merma Total de Insumos</h2>}
                    >
                        <div className="ant-list-box table-responsive">
                            <Table
                                style={{ width: '100%' }}
                                dataSource={supplyShrinkageDataSource}
                                columns={shrinkageColumns}
                                rowKey={(record, index) => index}
                                pagination={true}
                            />
                        </div>
                    </Card>
                </Col>
                <Col span={12} md={12}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: "0px" }}
                        style={{ padding: 20, marginLeft: 10 }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={<h2>Merma Total de Producto</h2>}
                    >
                        <div className="ant-list-box table-responsive">
                            <Table
                                style={{ width: '100%' }}
                                dataSource={productShrinkageDataSource}
                                columns={shrinkageColumns}
                                rowKey={(record, index) => index}
                                pagination={true}
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    )
}


export default ProductionSummary;
