import React, { Component } from "react";
import { Card, Col, Row, Table, Typography, Tag, Button, FloatButton } from "antd";
import animalia from "../../images/animale.jpeg";
import NewInventoryEntry from "./components/NewInventoryEntry";


const { Title } = Typography;

const WarehouseEntryList = () => {
  const [newEntry, setNewEntry] = React.useState(false);
  const [entry, setEntry] = React.useState(null);
    const dataSource = [
    {
      date: '10/06/2024',
      purchaseOrder: 'ODC012',
      supplier: 'Multisacos',
      received: "Alejandra Fernandez",
      products: [{
        name: 'Sacos de rafia 10 kg',
        quantity: 5000,
        unitCost: "3",
        baseUnit: 'Piezas',
        total: '$15,000.00',
      }, {
        name: 'Sacos de rafia 15 kg',
        quantity: 9000,
        unitCost: "7",
        baseUnit: 'Piezas',
        total: '$35,000.00',
      }]
    },
    {
      date: '08/06/2024',
      purchaseOrder: 'ODC011',
      supplier: "Proan",
      received: "Alejandra Fernandez",
      products: [{
        name: 'Sacos de rafia 15 kg',
        quantity: '9000',
        unitCost: "7",
        baseUnit: 'Piezas',
        total: '$35,000.00',
      }]
    },
    {
      date: '07/06/2024',
      purchaseOrder: 'ODC010',
      supplier: 'Multisacos',
      received: "Alejandra Fernandez",
      products: [{
        name: 'Sacos de rafia 20 kg',
        quantity: 2000,
        unitCost: "10",
        baseUnit: 'Piezas',
        total: '$75,000.00',
      }]
    },
    {
      date: '05/06/2024',
      purchaseOrder: 'ODC009',
      supplier: 'Previtep',
      received: "Alejandra Fernandez",
      products: [{
        name: 'Sacos de rafia 25 kg',
        quantity: 1000,
        unitCost: "15",
        baseUnit: 'Piezas',
        total: '$150,000.00',
      }]
    },
    {
      date: '01/06/2024',
      purchaseOrder: 'ODC008',
      supplier: 'Trigo richard',
      received: "Alejandra Fernandez",
      products: [{
        name: 'Sacos de rafia 30 kg',
        quantity: 500,
        unitCost: "20",
        baseUnit: 'Piezas',
        total: '$100,000.00',
      }]
    },
  ]
  
    const columns = [
    
      {
        title: "Fecha",
        dataIndex: "date",
        key: "date",
        render: (text) => (
          <>
            <h3>{text}</h3>
          </>
        ),
      },
      {
        title: "Orden de compra",
        dataIndex: "purchaseOrder",
        key: "purchaseOrder",
        render: (text, entry) => (
         <Button type='link' onClick={() => {
          setNewEntry(true)
          setEntry(entry)}
          }>
            <p>{text}</p>
          </Button>
        ),
      },
      {
        title: "Proveedor",
        dataIndex: "supplier",
        key: "supplier",
        render: (text) => (
          <>
            <p>{text}</p>
          </>
        ),
      },
      {
        title: "Recibido por",
        dataIndex: "received",
        key: "client",
        render: (text) => (
          <>
            <p>{text}</p>
          </>
        ),
      },
      {
        title: "Productos",
        dataIndex: "products",
        key: "products",
        render: (products) => (
          products?.map((product, index) => (
            <div key={index}>
              <p>{product.quantity?.toLocaleString()} {product.name}</p>
            </div>
          ))
        ),
      }, 
      
    ];

    return (
      <div className="layout-content">
        <NewInventoryEntry open={newEntry} setOpen={setNewEntry} entryInfo={entry} />
        <Row gutter={[24, 0]}>
          <Col lg={24} className="zindex">
            <h2 className="p-15 h2">Entradas de inventario</h2>

            <Card
              bordered={false}
              bodyStyle={{ padding: "0px" }}
              className="criclebox cardbody mb-24 header-solid"
              title={<h3>Entradas de inventario</h3>}
            >
              <div className="ant-list-box table-responsive">
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  rowKey={(record, index) => index}
                  pagination={true}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }


  export default WarehouseEntryList
