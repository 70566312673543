import React, { useEffect, useState } from "react";
import { Button, Card, Input, Space, Typography, Modal, Form, DatePicker, Select} from "antd";
import { MinusOutlined } from '@ant-design/icons';
import DebounceSelect from "../../../form/DebounceSelect";
import ProductList from "./ProductList";
import { fetchClient, fetchProducts } from "../utils/proto";
import AddressAutocomplete from "../../../form/PlacesSelect";
const { Title } = Typography;

const NewQuote = ({open, setOpen}) => {    
    const [form] = Form.useForm();
    const [renderId, setRenderId] = useState(0);
    const [disableSave, setDisableSave] = useState(false);
    const [deliveryAddress, setDeliveryAddress] = useState({});
    
    const handleCancel = () => {
        setOpen(false);
    };
    
    useEffect(() => {
        form.setFieldsValue({
            // deliveryDate: estimatedDeliveryDate(),
            products: [],
        });
    }, []);

    useEffect(() => {
        allowSave();
    }, [form.getFieldsValue()]);

    const allowSave = () => {
       let error = false;
        const values = form.getFieldsValue();
        if (!values.client || !values.deliveryDate || !values.deliveryPlace || !values.products || !values.products.length) {
            error = true;
        }
        setDisableSave(error);
    };
    

   const calculateTotal = () => {
        const products = form.getFieldValue("products");
        const deliveryPrice = form.getFieldValue("deliveryPrice") || 0;
        let total = products?.reduce((acc, product) => acc + product.total, 0) + Number(deliveryPrice);
        total = total.toFixed(2);
        return !isNaN(total) ? total : 0;
    }

    const handleAddressSelect = (value, place, setValue, clearSuggestions, getGeocode, getLatLng) => {
        let deliveryAddress = {}
        // console.log("📍 Address: ", place);
        const { description } = place;
        setValue(description, false);
        clearSuggestions();
    
        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
            // console.log("📍 Coordinates: ", { lat, lng });
            deliveryAddress = {
                address: description,
                lat: lat,
                lng: lng,
            };
        })
        .catch((error) => {
            console.log("😱 Error: ", error);
        });

        form.setFieldsValue({ deliveryAddress });
        setDeliveryAddress(deliveryAddress);

    };

    console.log("form", form.getFieldValue("deliveryAddress"));

    const handleSave = () => {
        console.log("form", form.getFieldsValue());
        form.getFieldsError();
        const values = form.getFieldsValue();
        console.log("values", values
        );
    }
    
    return (
        <Modal
        title="Nueva cotización"
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1200}
        >
        <Form form={form} layout="vertical">
            <Form.Item label="Cliente" name="client" rules={[{ required: true, message: "Selecciona un cliente" }]}>
            <DebounceSelect
                fetchOptions={fetchClient}
                placeholder="Buscar cliente"
                style={{ width: "100%" }}
                allowClear
                showSearch
            />
            </Form.Item>
            <Space direction="horizontal" style={{ width: "100%" }}>
            <Form.Item label="Fecha de entrega" name="deliveryDate" rules={[{ required: true, message: "Selecciona una fecha" }]}>
                <DatePicker style={{ width: "100%" }} placeholder="Selecciona una fecha" />
            </Form.Item>
            <Form.Item label="Lugar de entrega" name="deliveryPlace" rules={[{ required: true, message: "Selecciona un lugar de entrega" }]}>
                <Select placeholder="Selecciona un lugar de entrega" style={{ width: "100%" }}
                    onChange={(value) => {
                        form.setFieldsValue({ deliveryAddress: undefined, deliveryPrice: undefined });
                        setRenderId((prev) => prev + 1);
                    }} >
                    <Select.Option value="Domicilio">Domicilio</Select.Option>
                    <Select.Option value="Pie de fábrica">Pie de fábrica</Select.Option>
                </Select>
            </Form.Item>
            </Space>

            {form.getFieldValue("deliveryPlace") === "Domicilio" && (
                <>
                <Form.Item label="Dirección" name="deliveryAddress" rules={[{ required: true, message: "Ingresa una dirección" }]}>
                    <AddressAutocomplete onSelect={handleAddressSelect} />
                </Form.Item>
                <Form.Item label="Flete" name="deliveryPrice" rules={[{ required: true, message: "Ingresa un precio para el flete" }]}>
                    <Input onBlur={() => setRenderId((prev) => prev + 1)} type="number" placeholder="Precio del flete" onClick={(e) => e.target.select()} onClear={() => setRenderId((prev) => prev + 1)} />
                </Form.Item>
                </>
            )}
            <Form.Item label="Productos" name="products" rules={[{ required: true, message: "Selecciona al menos un producto" }]}>
                <ProductList form={form} setRenderId={setRenderId} />
            </Form.Item>
            
        </Form>
        <Space direction="horizontal" style={{ width: "100%" }}>
            <Title level={4}>Total: ${String(calculateTotal()).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Title>
        </Space>
        <Button type="primary" htmlType="submit" style={{ position: "absolute", bottom: 20, right: 20 }} disabled={disableSave} onClick={handleSave}>
                Guardar
            </Button>
        </Modal>
    );
    }

export default NewQuote;