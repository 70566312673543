import React from 'react';
import { Row, Col, Avatar, Switch, Card, Statistic, Table } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import ReactApexChart from "react-apexcharts";

function onChange(checked) {
  console.log(`switch to ${checked}`);
}

const columns = [
  {
    title: "Fecha",
    dataIndex: "Date",
    key: "Date",
    render: (text) => <h3>{text}</h3>,
  },
  {
    title: "Orden de compra",
    dataIndex: "Order",
    key: "Order",
    render: (text) => <h3>{text}</h3>,
  },
  {
    title: "Producto",
    dataIndex: "Product",
    key: "Product",
    render: (text) => <h3>{text}</h3>,
  },
  {
    title: "Cantidad",
    dataIndex: "Quantity",
    key: "Quantity",
    render: (text) => <h3>{text}</h3>,
  },
  {
    title: "Precio",
    dataIndex: "cost",
    key: "cost",
    render: (text) => <h3>{text}</h3>,
  },
];

const dataSource = [
  {
    Date: "16/06/2024",
    Order: "D0001",
    Product: 'Croqueta para perro',
    Quantity: '100 kg',
    cost: '$2,450.00',
  },
];

const options = {
  chart: {
    width: "100%",
    height: 350,
    type: "area",
    toolbar: {
      show: false,
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    colors: ["#1890FF", "#B37FEB"],
    width: 3,
  },
  yaxis: {
    labels: {
      style: {
        fontSize: "14px",
        fontWeight: 600,
        colors: ["#8c8c8c"],
      },
      offsetY: -50,
    },
  },
  xaxis: {
    labels: {
      style: {
        fontSize: "14px",
        fontWeight: 600,
        colors: ["#8c8c8c"],
      },
    },
    categories: [
      "Ene",
      "Feb",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
  },
  grid: {
    show: true,
    borderColor: "rgba(0, 0, 0, .2)",
    strokeDashArray: 6,
    position: "back",
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
    row: {
      colors: undefined,
      opacity: 0.5,
    },
    column: {
      colors: undefined,
      opacity: 0.5,
    },
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val;
      },
    },
  },
};

const series = [
  {
    name: "Compras en pesos",
    data: [50, 40, 300, 220, 500, 250],
    offsetY: 0,
  },
  {
    name: "Kilos",
    data: [30, 90, 40, 140, 290, 290],
    offsetY: 0,
  },
];

const InventoryDetails = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={8} md={12} lg={12}>
        <Card
          id="profile"
          className="card-profile-head"
          bordered={false}
          bodyStyle={{ display: "none" }}
          title={
            <Row justify="space-between" align="middle" gutter={[24, 0]}>
              <Col span={24} md={12} className="col-info">
                <Avatar.Group>
                  <Avatar size={74} shape="square" src="https://http2.mlstatic.com/D_NQ_NP_961228-MLM72596894113_102023-O.webp" />
                  <div className="avatar-info">
                    <h4 className="font-semibold m-0">Saco para envasado 10 kg</h4>
                    <div style={{ color: "gray" }}>Unidad de Medida: Piezas</div>
                    <div style={{ color: "gray" }}>Costo: $3.00</div>
                  </div>
                </Avatar.Group>
              </Col>
              <Col
                span={24}
                md={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <small className="font-regular mr-5">
                  Activo
                </small>
                <Switch defaultChecked onChange={onChange} />
              </Col>
            </Row>
          }
        ></Card>
      </Col>
      <Col span={8} md={8} lg={6} id="clientStats">
        <Card title="Existencia" style={{ width: "100%" }}>
          <Row gutter={16}>
              <Statistic
                title="Unidades"
                value={"500"}
                valueStyle={{ color: '#a7c957' }} />
          </Row>
        </Card>
      </Col>
      <Col span={8} md={8} lg={6} id="clientStats">
        <Card title="Costo Total" style={{ width: "100%" }}>
          <Row gutter={16}>
              <Statistic
                title="Total"
                value={"$1,500"}
                valueStyle={{ fontSize: 18 }} />
          </Row>
        </Card>
      </Col>

      <Col span={24}>
        <Card
          style={{ padding: 20 }}
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          className="criclebox cardbody mb-24 header-solid"
          title={<h2>Compras</h2>}
        >
          <div className="ant-list-box table-responsive">
            <div className="linechart">
              <div></div>
              <div className="sales">
                <ul>
                  <li><MinusOutlined /> Entradas</li>
                  <li><MinusOutlined /> Salidas</li>
                </ul>
              </div>
            </div>

            <ReactApexChart
              className="full-width"
              options={options}
              series={series}
              type="area"
              height={350}
              width={"100%"}
            />
          </div>
        </Card>
      </Col>

      <Col span={24}>
        <Card
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          style={{ padding: 20 }}
          className="criclebox cardbody mb-24 header-solid"
          title={<h2>Clientes</h2>}
        >
          <div className="ant-list-box table-responsive">
            <Table
              style={{ width: '100%' }}
              dataSource={dataSource}
              columns={columns}
              rowKey={(record, index) => index}
              pagination={true}
            />
          </div>
        </Card>
      </Col>
    </Row>
  )
}

export default InventoryDetails;
