// Template
import DataTable from "../component/applications/DataTable";
import FullCalender from "../component/applications/FullCalender";
import Kanban from "../component/applications/Kanban";
import Wizard from "../component/applications/Wizard";
import Invoice from "../component/pages/account/Invoice";
import Settings from "../component/pages/account/Settings";
import Alerts from "../component/pages/Alerts";
import Billing from "../component/pages/Billing";
import ChartLine from "../component/pages/ChartLine";
import CRM from "../component/pages/CRM";
import OrderDetails from "../component/pages/ecommerce/order/OrderDetails";
import OrderList from "../component/pages/ecommerce/order/OrderList";
import EditProduct from "../component/pages/ecommerce/Products/EditProduct";
import NewProduct from "../component/pages/ecommerce/Products/NewProduct";
import ProductPage from "../component/pages/ecommerce/Products/ProductPage";
import Home from "../component/pages/Home";
import PurchasesList from "../component/pages/purchases/PurchasesList";
import ClientsList from '../component/pages/clients/ClientsList';
import SellersList from '../component/pages/sellers/SellersList';
import SuppliersList from '../component/pages/supplier/SuppliersList';
import Notifications from "../component/pages/Notifications";
import Profile from "../component/pages/Profile";
import AllProject from "../component/pages/profile/AllProject";
import TimeLine from "../component/pages/projects/TimeLine";
import Rtl from "../component/pages/Rtl";
import NewUsers from "../component/pages/users/NewUsers"
import SalesList from "../component/pages/sales/SalesList";
import WarehouseEntryList from "../component/pages/Inventory/WarehouseEntryList";
import ClientDetails from "../component/pages/clients/ClientDetails/ClientDetails";
import SellerDetails from "../component/pages/sellers/SellerDetails/SellerDetails";
import SupplierDetails from "../component/pages/supplier/SupplierDetails";
import PurchaseOrder from "../component/pages/purchases/PurchaseOrder";
import QuoteDetail from "../component/pages/sales/QuoteDetail";
import SalesDetails from "../component/pages/sales/SalesDetails";
// Custom
import PurchaseDetails from "../component/pages/purchases/PurchaseDetails";
import WarehouseEntryDetail from "../component/pages/Inventory/WarehouseEntryDetail";
import InputInventory from "../component/pages/Inventory/InputInventory";
import InventoryDetails from "../component/pages/Inventory/InventoryDetails";
import Recipe from "../component/pages/Inventory/Recipe";
import ProductInventory from "../component/pages/Inventory/ProductInventory";
import ProductDetails from "../component/pages/Inventory/ProductDetails";
import ProductionList from "../component/pages/production/ProductionList";
import WarehouseOutput from "../component/pages/Inventory/WarehouseOutput.js"; 
import WarehouseOutputList from "../component/pages/Inventory/WarehouseOutputList.js"; 
import OKRsDetails from "../component/pages/okrs/okrsDetails.js"; 
import ProductionDetails from "../component/pages/production/ProductionDetails.js"; 
import FinanceList from "../component/pages/finance/FinanceList"; 

const routes = [
  {
    path: "/dashboard",
    exact: true,
    name: "Default",
    component: SalesList,
  },
  {
    path: "/Clients",
    exact: true,
    name: "Default",
    component: SalesList,
  },
  {
    path: "/sales/SalesDetails",
    exact: true,
    name: "Detalle de Venta",
    component: SalesDetails
  },
  {
    path: "/dashboard/crm",
    exact: true,
    name: "CRM",
    component: CRM,
  },
  {
    path: "/pages/profile/profile-overview",
    exact: true,
    name: "Profile Overview",
    component: Profile,
  },
  {
    path: "/pages/profile/all-projects",
    exact: true,
    name: "All Project",
    component: AllProject,
  },
  {
    path: "/pages/users/new-user",
    exact: true,
    name: "New User",
    component: NewUsers,
  },
  {
    path: "/pages/account/settings",
    exact: true,
    name: "Settings",
    component: Settings,
  },
  {
    path: "/pages/account/billing",
    exact: true,
    name: "Billing",
    component: Billing,
  },
  {
    path: "/pages/account/invoice",
    exact: true,
    name: "Invoice",
    component: Invoice,
  },
  {
    path: "/pages/projects/timeline",
    exact: true,
    name: "Timeline",
    component: TimeLine,
  },
  {
    path: "/pages/rtl",
    exact: true,
    name: "RTL",
    component: Rtl,
  },
  {
    path: "/pages/charts",
    exact: true,
    name: "Charts",
    component: ChartLine,
  },
  {
    path: "/pages/alerts",
    exact: true,
    name: "Alerts",
    component: Alerts,
  },
  {
    path: "/pages/notifications",
    exact: true,
    name: "Notification",
    component: Notifications,
  },
  {
    path: "/applications/kanban",
    exact: true,
    name: "Kanban",
    component: Kanban,
  },
  {
    path: "/applications/wizard",
    exact: true,
    name: "Wizard",
    component: Wizard,
  },
  {
    path: "/applications/data-table",
    exact: true,
    name: "DataTable",
    component: DataTable,
  },
  {
    path: "/applications/calendar",
    exact: true,
    name: "Calender",
    component: FullCalender,
  },
  {
    path: "/ecommerce/products/new-product",
    exact: true,
    name: "New Product",
    component: NewProduct,
  },
  {
    path: "/ecommerce/products/edit-product",
    exact: true,
    name: "Edit Product",
    component: EditProduct,
  },
  {
    path: "/ecommerce/products/product-page",
    exact: true,
    name: "Product Page",
    component: ProductPage,
  },
  {
    path: "/ecommerce/orders/order-list",
    exact: true,
    name: "Order List",
    component: OrderList,
  },
  {
    path: "/ecommerce/orders/order-details",
    exact: true,
    name: "Order List",
    component: OrderDetails,
  },
  {
    path: '/purchases/detail',
    exact: true,
    name: "Detalle de compra",
    component: PurchaseDetails,
  },
  {
    path: '/clients/clientslist',
    exact: true,
    name: 'Lista de clientes',
    component: ClientsList
  }, {
    path: '/sellers/sellerslist',
    exact: true,
    name: 'Lista de vendedores',
    component: SellersList
  },
  {
    path: '/supplier/supplierslist',
    exact: true,
    name: 'Lista de provedores',
    component: SuppliersList

  },
  {
    path: '/purchases/PurchasesList',
    exact: true,
    name: 'Lista de compras',
    component: PurchasesList

  },
  {
    path: "/sales/SalesList",
    exact: true,
    name: "Lista de ventas",
    component: SalesList
  },
  {
    path: "/Inventory/WarehouseEntryList",
    exact: true,
    name: "Lista de entrada Almacén",
    component: WarehouseEntryList
  },
  {
    path: "/clients/details",
    exact: true,
    name: "Detalle de cliente",
    component: ClientDetails
  },
  {
    path: "/seller/details",
    exact: true,
    name: "Detalle de Vendedor",
    component: SellerDetails
  },
  {
    path: "/supplier/details",
    exact: true,
    name: "Detalle de Proveedor",
    component: SupplierDetails
  },
  {
    path: "/purchases/order",
    exact: true,
    name: "Orden de compra",
    component: PurchaseOrder
  },
  {
    path: "/sales/quoteDetail",
    exact: true,
    name: "Detalle de cotización",
    component: QuoteDetail
  },
  {
    path: "/inventory/WarehouseEntryDetail",
    exact: true,
    name: "Detalle Entrada a Almacén",
    component: WarehouseEntryDetail
  },
  {
    path: "/inventory/InputInventory",
    exact: true,
    name: "Inventario de Insumos",
    component: InputInventory
  },
  {
    path: "/inventory/InventoryDetails",
    exact: true,
    name: "Detalle de Insumo",
    component: InventoryDetails
  },
  {
    path: "/inventory/Recipe",
    exact: true,
    name: "Receta",
    component: Recipe
  },
  {
    path: "/inventory/ProductInventory",
    exact: true,
    name: "Inventario de Producto",
    component: ProductInventory

  },
  {
    path: "/inventory/ProductDetails",
    exact: true,
    name: "Detalles de Producto",
    component: ProductDetails
  },
  {
    path: "/production/ProductionList",
    exact: true,
    name: "Lista de Producción",
    component: ProductionList
  },
  {
    path: "/inventory/WarehouseOutput",
    exact: true,
    name: "Salida de almacen",
    component: WarehouseOutput
  },
  {
    path: "/inventory/WarehouseOutputList",
    exact: true,
    name: "Lista de salidas de almacen",
    component: WarehouseOutputList
  },
  {
    path: "/okrs/okrsDetails",
    exact: true,
    name: "Detalles de OKRs",
    component: OKRsDetails
  },
  {
    path: "/production/ProductionDetails",
    exact: true,
    name: "Detalles de Producción",
    component: ProductionDetails
  },
  {
    path: "/finance/FinanceList/",
    exact: true,
    name: "Balance financiero",
    component: FinanceList
  }, 
];

export default routes;
