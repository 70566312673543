import React, { Component } from "react";
import { Card, Col, Row, Table, Typography, Tag, Avatar } from "antd";
import animalia from "../../images/animale.jpeg";

const { Title } = Typography;

const SellersList = () => {
    const dataSource = [
      {
        img: "https://i.pinimg.com/564x/75/67/79/756779599fedeb021b46382ae39dfb46.jpg",
        name: 'Mariana Aldrete',
        email: 'mariana.ald@dasner.com',
        phone: '378123567 Ext. 15',
        sales: "30 kg",
        balance: '$22,458.20',
      },
      {
        img: "https://i.pinimg.com/564x/6d/5d/3c/6d5d3c44e68300609bade3c41f03c4c2.jpg", 
        name: 'Jaime Aldrete',
        email: 'Jimmy.ald@dasner.com',
        phone: '378123567 Ext. 8',
        sales: '45 Kg',
        balance: '$35,456.00',
      },
      {
        img: "https://i.pinimg.com/564x/d0/42/9b/d0429baa2117ec20ffcdad3cb88e7539.jpg",
        name: 'Fernando López',
        email: 'fer.lopez@dasner.com',
        phone: "378123567 Ext. 9",
        sales: '200 Kg',
        balance: '$45,527.00',
      },
      {
        img: "https://i.pinimg.com/564x/75/3d/76/753d76a297a724a614d11ce80f6966df.jpg",
        name: 'Emanuel Martín',
        email: 'emanuel.martin@dasner.com',
        phone: "378123567 Ext. 10",
        sales: '40 Kg',
        balance: '$23,678.50',
      },
      {
        img: "https://i.pinimg.com/564x/ee/9a/f2/ee9af26042e58bfa58da41225d172e0b.jpg",
        name: 'Alejandro Ramírez',
        email: 'Alex.ramirez@dasner.mx',
        phone: "378123567 Ext. 14",
        sales: '300 Kg',
        balance: '$60,345.67',
      },
    ];
  
    const columns = [
      {
        dataIndex: "img",
        key: "img",
        render: (img) => <img src={img} alt="" className="avatar-sm mr-10" style={{width: "45px"}}/>,
      },
      {
        title: "Nombre",
        dataIndex: "name",
        key: "name",
        render: (text) => (
          <>
            <h3>{text}</h3>
          </>
        ),
      },
      {
        title: "Correo electrónico",
        dataIndex: "email",
        key: "email",
        render: (text) => (
          <>
            <p>{text}</p>
          </>
        ),
      },
      {
        title: "Número de teléfono",
        dataIndex: "phone",
        key: "phone",
        render: (text) => (
          <>
            <p>{text}</p>
          </>
        ),
      },
      {
        title: "Volumen de ventas",
        dataIndex: "sales",
        key: "sales",
        render: (text) => (
          <>
            <p>{text}</p>
          </>
        ),
      },
      {
        title: "Balance financiero",
        dataIndex: "balance",
        key: "balance",
        render: (text) => (
          <>
            <h3>{text}</h3>
          </>
        ),
      },
    ];

    return (
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col lg={24} className="zindex">
            <h2 className="p-15 h2">Vendedores</h2>

            <Card
              bordered={false}
              bodyStyle={{ padding: "0px" }}
              className="criclebox cardbody mb-24 header-solid"
              title={<h3>Vendedores</h3>}
            >
              <div className="ant-list-box table-responsive">
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  rowKey={(record, index) => index}
                  pagination={true}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }


  export default SellersList
