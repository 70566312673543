import React from 'react';
import { Row, Col, Card, Table } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import ReactApexChart from "react-apexcharts";

const columns = [
  {
    title: "Fecha",
    dataIndex: "Date",
    key: "Date",
    render: (text) => <h3>{text}</h3>,
  },
  {
    title: "Producto",
    dataIndex: "Product",
    key: "Product",
    render: (text) => <h3>{text}</h3>,
  },
  {
    title: "Cantidad",
    dataIndex: "Quantity",
    key: "Quantity",
    render: (text) => <h3>{text}</h3>,
  },
  {
    title: "Productor",
    dataIndex: "packer",
    key: "packer",
    render: (text) => <h3>{text}</h3>,
  },
];

const dataSource = [
  {
    Date: "16/06/2024",
    Order: "D0001",
    Product: 'Croqueta para perro',
    Quantity: '100 kg',
    packer: 'Pedro Torres',
  },
  {
    Date: "16/06/2024",
    Order: "D0002",
    Product: 'Croqueta para gato',
    Quantity: '150 kg',
    packer: 'German Fernandez',
  },
  {
    Date: "17/06/2024",
    Order: "D0003",
    Product: 'Croqueta para perro',
    Quantity: '200 kg',
    packer: 'Alejandro Barrera',
  },
  {
    Date: "17/06/2024",
    Order: "D0004",
    Product: 'Croqueta para gato',
    Quantity: '250 kg',
    packer: 'Sandra Franco',
  },
  {
    Date: "18/06/2024",
    Order: "D0005",
    Product: 'Croqueta para perro',
    Quantity: '180 kg',
    packer: 'Hilario Tapia',
  },
  {
    Date: "18/06/2024",
    Order: "D0006",
    Product: 'Croqueta para gato',
    Quantity: '230 kg',
    packer: 'Brisa Ramirez',
  },
  {
    Date: "19/06/2024",
    Order: "D0007",
    Product: 'Croqueta para perro',
    Quantity: '160 kg',
    packer: 'Juan Perez',
  },
  {
    Date: "19/06/2024",
    Order: "D0008",
    Product: 'Croqueta para gato',
    Quantity: '210 kg',
    packer: 'Guadalupe Torres',
  },
  {
    Date: "20/06/2024",
    Order: "D0009",
    Product: 'Croqueta para perro',
    Quantity: '140 kg',
    packer: 'Jaime Calles',
  },
  {
    Date: "20/06/2024",
    Order: "D0010",
    Product: 'Croqueta para gato',
    Quantity: '190 kg',
    packer: 'Rodrigo Barba',
  },
  {
    Date: "21/06/2024",
    Order: "D0011",
    Product: 'Croqueta para perro',
    Quantity: '120 kg',
    packer: 'Juan Lopez',
  },
  {
    Date: "21/06/2024",
    Order: "D0012",
    Product: 'Croqueta para gato',
    Quantity: '170 kg',
    packer: 'Fernando Lara',
  },
  {
    Date: "22/06/2024",
    Order: "D0013",
    Product: 'Croqueta para perro',
    Quantity: '100 kg',
    packer: 'Martin Fernandez',
  },
  {
    Date: "22/06/2024",
    Order: "D0014",
    Product: 'Croqueta para gato',
    Quantity: '150 kg',
    packer: 'Emanuel Bernal',
  },
  {
    Date: "23/06/2024",
    Order: "D0015",
    Product: 'Croqueta para perro',
    Quantity: '80 kg',
    packer: 'Carlos Jimenez',
  },
  {
    Date: "23/06/2024",
    Order: "D0016",
    Product: 'Croqueta para gato',
    Quantity: '130 kg',
    packer: 'Frania Barrera',
  },
  {
    Date: "24/06/2024",
    Order: "D0017",
    Product: 'Croqueta para perro',
    Quantity: '60 kg',
    packer: 'Chris Gutierrez',
  },
  {
    Date: "24/06/2024",
    Order: "D0018",
    Product: 'Croqueta para gato',
    Quantity: '110 kg',
    packer: 'Jessie Gomez',
  },
  {
    Date: "25/06/2024",
    Order: "D0019",
    Product: 'Croqueta para perro',
    Quantity: '40 kg',
    packer: 'Arturo Lopez',
  },
  {
    Date: "25/06/2024",
    Order: "D0020",
    Product: 'Croqueta para gato',
    Quantity: '90 kg',
    packer: 'Fernanda Rizo',
  },
];

// Helper function to parse quantity
const parseQuantity = (quantity) => parseInt(quantity.replace(' kg', ''));

// Group data by date and product
const groupedData = dataSource.reduce((acc, { Date, Product, Quantity }) => {
  if (!acc[Date]) acc[Date] = {};
  if (!acc[Date][Product]) acc[Date][Product] = 0;
  acc[Date][Product] += parseQuantity(Quantity);
  return acc;
}, {});

// Get unique product names
const products = [...new Set(dataSource.map(item => item.Product))];

// Create series data for each product and the total
const series = products.map(product => ({
  name: product,
  data: Object.keys(groupedData).map(date => groupedData[date][product] || 0)
}));

// Add total series
const totalSeries = {
  name: "Total",
  data: Object.keys(groupedData).map(date => 
    products.reduce((sum, product) => sum + (groupedData[date][product] || 0), 0)
  )
};
series.push(totalSeries);

const dates = Object.keys(groupedData);

// Function to generate random colors
const generateRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Generate colors for each series
const colors = series.map(() => generateRandomColor());

const options = {
  chart: {
    width: "100%",
    height: 350,
    type: "area",
    toolbar: {
      show: false,
    },
  },
  legend: {
    show: true,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 3,
  },
  colors: colors, // Dynamic colors
  yaxis: {
    labels: {
      style: {
        fontSize: "14px",
        fontWeight: 600,
        colors: ["#8c8c8c"],
      },
      offsetY: -50,
    },
  },
  xaxis: {
    labels: {
      style: {
        fontSize: "14px",
        fontWeight: 600,
        colors: ["#8c8c8c"],
      },
    },
    categories: dates,
  },
  grid: {
    show: true,
    borderColor: "rgba(0, 0, 0, .2)",
    strokeDashArray: 6,
    position: "back",
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
    row: {
      colors: undefined,
      opacity: 0.5,
    },
    column: {
      colors: undefined,
      opacity: 0.5,
    },
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val + " kg";
      },
    },
  },
};

const InventoryDetails = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Card
          style={{ padding: 20 }}
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          className="criclebox cardbody mb-24 header-solid"
          title={<h2>Volumen de producción</h2>}
        >
          <div className="ant-list-box table-responsive">
            <div className="linechart">
              <div></div>
              <div className="sales">
                <ul>
                  {products.map((product, index) => (
                    <li key={index}><MinusOutlined /> {product}</li>
                  ))}
                  <li><MinusOutlined /> Total</li>
                </ul>
              </div>
            </div>

            <ReactApexChart
              className="full-width"
              options={options}
              series={series}
              type="area"
              height={350}
              width={"100%"}
            />
          </div>
        </Card>
      </Col>
      <Col span={24}>
        <Card
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          style={{ padding: 20 }}
          className="criclebox cardbody mb-24 header-solid"
          title={<h2>Producción</h2>}
        >
          <div className="ant-list-box table-responsive">
            <Table
              style={{ width: '100%' }}
              dataSource={dataSource}
              columns={columns}
              rowKey={(record, index) => index}
              pagination={true}
            />
          </div>
        </Card>
      </Col>
      
    </Row>
  );
};

export default InventoryDetails;
