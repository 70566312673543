import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Space, Input, message, Select } from "antd";
import DebounceSelect from "../../../form/DebounceSelect";
import { PlusOutlined } from "@ant-design/icons";
import NewPresentation from "./NewPresentation";
import { presentations } from "../utils/proto";
const { Title, Text } = Typography;

const IngredientList = ({ form }) => {
    const [products, setProducts] = useState([]);
    const [newPresentation, setNewPresentation] = useState(false);

    useEffect(() => {
        form.getFieldValue("products") && setProducts(form.getFieldValue("products"));
    }, [form]);
    

    const onProductSelected = (objectId, product) => {
        console.log("product", product);
        const products = form.getFieldValue("products");
        if (products.find((p) => p.objectId === product.objectId)) {
            return;
        }
        products.push({
            ...product,
            quantity: 1,
            total: 0,
        });
        form.setFieldValue("products", products);
        setProducts(products);
    };

    return (
        <Space direction="horizontal" style={{ width: "100%" }}>
            <NewPresentation open={newPresentation} setOpen={setNewPresentation} parentForm={form} />
            {presentations.map((presentation, index) => (
                <Button key={index} style={{ width: "180px", height: "240px" }}>
                    <Space direction="vertical">
                        <Title level={5}>{presentation.name}</Title>
                        <Text level={5}>{presentation.content} {presentation.unit}</Text>
                        <Text level={3} type="secondary">${presentation.minPrice}</Text>
                        {presentation?.package?.map((pack, index) => (
                            <>
                            <Text key={index}>{pack.name}</Text>
                            <Text key={index}>{pack.quantity} {pack.unit}</Text>
                            </>
                        ))}
                    </Space>
                </Button>
            ))}
            <Button
                style={{ width: "180px", height: "240px" }}
                type="dashed"
                onClick={() => setNewPresentation(true)}
            >
                <Space direction="vertical">
                    <PlusOutlined />
                    <Title level={5}>Agregar presentación</Title>
                </Space>
            </Button>
       
        </Space>
    );
}

export default IngredientList;
