import React from "react";
import {
  Tabs
} from "antd";
import { SellerInfo, SellerSales, SellerComissions } from "./Tabs";
import { Card, Statistic, Row, Col } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';



const items = [
  {
    key: "1",
    label: `Información`,
    children: <SellerInfo />
  },
  {
    key: "2",
    label: `Ventas`,
    children: <SellerSales />
  },
  {
    key: "3",
    label: `Comisiones`,
    children: <SellerComissions />
}
]
const StatisticsCard = () => {
<Card title="Estadísticas" style={{ width: 300 }}>
<Row gutter={16}>
  <Col span={12}>
    <Statistic
      title="Activos"
      value={1128}
      valueStyle={{ color: '#3f8600' }}
      prefix={<ArrowUpOutlined />}
      suffix="%"
    />
  </Col>
  <Col span={12}>
    <Statistic
      title="Inactivos"
      value={93}
      valueStyle={{ color: '#cf1322' }}
      prefix={<ArrowDownOutlined />}
      suffix="%"
    />
  </Col>
</Row>
</Card>
};

const SellerDetails = () => (
      <>
        <Tabs
          className="tabs-sliding"
          defaultActiveKey="1"
          items={items}
        />
      </>
    );

export default SellerDetails;