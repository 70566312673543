import React from "react"
import { Row, Col, Card, Table, Space, Statistic, Avatar } from "antd"
import Title from "antd/lib/skeleton/Title";
import { FontSizeOutlined } from "@ant-design/icons";

const dataSource = [
    {
        date: '10/06/2024',
        quantity: '150',
        description: '10 costales perro 10 kg',
        supplier: 'Multisacos',
    },
    {
        date: '10/06/2024',
        quantity: '100',
        description: '5 costales gato 5 kg',
        supplier: 'Multisacos',
    },
];


const columns = [
    {
        title: "Fecha",
        dataIndex: "date",
        key: "date",
        render: (text) => (
            <>
                <h3>{text}</h3>
            </>
        ),
    },
    {
        title: "Cantidad",
        dataIndex: "quantity",
        key: "quantity",
        render: (text) => (
            <>
                <p>{text}</p>
            </>
        ),
    },
    {
        title: "Descripción",
        dataIndex: "description",
        key: "description",
        render: (text) => (
            <>
                <p>{text}</p>
            </>
        ),
    },
    {
        title: "Proveedor",
        dataIndex: "supplier",
        key: "supplier",
        render: (text) => (
            <>
                <p>{text}</p>
            </>
        ),
    },
];

const WarehouseEntryDetail = () => {
    return (

        <Row gutter={[24, 0]}>
            <Col span={18}>
                <Row>
                    <h1 className="ant-card-head-title" style={{ fontSize: "30px", fontWeight: "bold" }}>Detalle de Recepción</h1>
                </Row>
                <Row>
                    <p style={{ fontWeight: "bold" }}>Número de Entrada:</p>
                    <p> No. #D0005</p>
                </Row>
                <Row>
                    <p style={{ fontWeight: "bold" }}>Fecha:</p>
                    <p>12/07/2024</p>
                </Row>
            </Col>
            <Col span={6}>
                <Col span={3} md={12}>
                    <span style={{ verticalAlign: "left" }}>Recibido por</span>
                </Col>
                <Col span={3} md={12}>
                    <Avatar size="small" className="mr-10">
                        L
                    </Avatar>
                    <span style={{ verticalAlign: "bottom" }}>Laur Gilbert</span>
                </Col>
            </Col>
            <Row>
                <Col span={8} lg={50} className="zindex">
                    <Card>
                        <h2>Información de orden</h2>
                        <Card>
                            <h4> Orden de compra</h4>
                            <h6>No. #D0005</h6>
                            <p> Fecha: 20/06/2024</p>
                            <p> Provedor: Multisacos</p>
                            <h8> Recibió: Fernanda Ramírez </h8>
                        </Card>
                    </Card>
                </Col>
                <Col span={8} lg={50} className="zindex">
                    <Card>
                        <h2>Fotos evidencia</h2>
                        <img src="https://www.shutterstock.com/image-photo/container-trucks-parked-loading-package-600nw-2376463087.jpg" />
                    </Card>
                </Col>
                <Col span={8} lg={50} className="zindex">
                    <Card>
                        <h2>Documentos</h2>
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9iED2-Dm4_cplLxHEYH6cNXETI-PUISRAyA&s" />
                    </Card>
                </Col>
                <Col span={24} lg={50} className="zindex">
                    <Card title={<h2>Productos</h2>}>
                        <div className="ant-list-box table-responsive">
                            <Table
                                dataSource={dataSource}
                                columns={columns}
                                rowKey={(record, index) => index}
                                pagination={true}
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
        </Row>
    )
}

export default WarehouseEntryDetail; 