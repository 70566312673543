import React from "react";
import { Card, Col, Row, Table, Typography, Statistic, FloatButton } from "antd";
import ReactApexChart from "react-apexcharts";
import NewIngredient from "./components/NewIngredient";

const { Title } = Typography;

const InputInventory = () => {
  const dataSource = [
    {
      date: '10/06/2024',
      name: 'Sacos de rafia 10 kg',
      quantity: '5000',
      unitCost: "3",
      measure: 'Piezas',
      total: '$15,000.00',
    },
    {
      date: '06/06/2024',
      name: 'Sacos de rafia 15 kg',
      quantity: '9000',
      unitCost: "7",
      measure: 'Piezas',
      total: '$35,000.00',
    },
    {
      date: '02/06/2024',
      name: 'Sacos de rafia 20 kg',
      quantity: '2000',
      unitCost: "10",
      measure: 'Piezas',
      total: '$75,000.00',
    },
  ];


  // Calculando la suma de TotalValue de todos los elementos en dataSource
  const totalValueSum = dataSource.reduce((acc, item) => acc + parseFloat(item.total.replace('$', '').replace(',', '')), 0);
  const totalVolumeSum = dataSource.reduce((acc, item) => acc + parseInt(item.quantity), 0);
  const [newIngredientModal, setNewIngredientModal] = React.useState(false);

  // Definición de columnas para la tabla de insumos
  const columns = [
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
      render: (text) => <h3>{text}</h3>,
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Costo Unitario",
      dataIndex: "unitCost",
      key: "unitCost",
      render: (text) => <p>{`$ ${text}`}</p>,
    },
    {
      title: "UM",
      dataIndex: "measure",
      key: "measure",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text) => <h3>{text}</h3>,
    },
  ];

  // Preparar datos para la gráfica de líneas
  const chartData = {
    series: [
      {
        name: "Valor Total",
        data: dataSource.map(item => ({ x: new Date(item.date), y: parseFloat(item.total.replace('$', '').replace(',', '')) })),
      },
      {
        name: "Volumen Total",
        data: dataSource.map(item => ({ x: new Date(item.date), y: parseInt(item.quantity) })),
      },
    ],
    options: {
      chart: {
        type: 'line',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        categories: dataSource.map(item => new Date(item.date)),
      },
      yaxis: [
        {
          title: {
            text: 'Valor ($)',
          },
        },
        {
          opposite: true,
          title: {
            text: 'Volumen (Unidades)',
          },
        },
      ],
      tooltip: {
        x: {
          format: 'dd/MM/yyyy',
        },
        y: {
          formatter: function (val, opts) {
            return opts.seriesIndex === 0 ? `$ ${val.toLocaleString()}` : `${val} unidades`;
          },
        },
      },
    },
  };

  return (
    <div className="layout-content">
      <FloatButton onClick={() => setNewIngredientModal(true)} />
      <NewIngredient open={newIngredientModal} setOpen={setNewIngredientModal} />
      <Row gutter={[24, 0]}>
        <Col lg={24} className="zindex">
          <h2 className="p-15 h2">Inventario de insumos</h2>

          <Card
            bordered={false}
            bodyStyle={{ padding: "0px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Insumos</h3>}
          >
            <div className="ant-list-box table-responsive">
              <Table
                dataSource={dataSource}
                columns={columns}
                rowKey={(record, index) => index}
                pagination={true}
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col lg={12} md={24} className="zindex">
          <Card
            bordered={false}
            bodyStyle={{ padding: "20px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Valor en $</h3>}
          >
            <Statistic title="Total" value={totalValueSum} prefix="$" style={{ fontSize: '20px' }} />
          </Card>
        </Col>
        <Col lg={12} md={24} className="zindex">
          <Card
            bordered={false}
            bodyStyle={{ padding: "20px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Volúmen</h3>}
          >
            <Statistic title="Total" value={totalVolumeSum} suffix="unidades" style={{ fontSize: '20px' }} />
          </Card>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Card
            bordered={false}
            bodyStyle={{ padding: "20px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Estadísticas de Inventario</h3>}
          >
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="line"
              height={350}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default InputInventory;
