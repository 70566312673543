import React, { useEffect, useState } from "react";
import { Button, Card, Input, Space, Typography, Modal, Form, DatePicker, Select} from "antd";
import { MinusOutlined } from '@ant-design/icons';
import DebounceSelect from "../../../form/DebounceSelect";
import ProductList from "./ProductList";
import { fetchProviders, fetchProducts } from "../utils/proto";
import AddressAutocomplete from "../../../form/PlacesSelect";
const { Title } = Typography;

const NewPurchaseOrder = ({open, setOpen}) => {    
    const [form] = Form.useForm();
    const [renderId, setRenderId] = useState(0);
    const [disableSave, setDisableSave] = useState(false);
    const [deliveryAddress, setDeliveryAddress] = useState({});
    
    const handleCancel = () => {
        setOpen(false);
    };
    
    useEffect(() => {
        form.setFieldsValue({
            // deliveryDate: estimatedDeliveryDate(),
            products: [],
        });
    }, []);

    useEffect(() => {
        allowSave();
    }, [form.getFieldsValue()]);

    const allowSave = () => {
       let error = false;
        const values = form.getFieldsValue();
        if (!values.provider || !values.deliveryDate || !values.deliveryPlace || !values.products || !values.products.length) {
            error = true;
        }
        setDisableSave(error);
    };
    

   const calculateTotal = () => {
        const products = form.getFieldValue("products");
        const deliveryPrice = form.getFieldValue("deliveryPrice") || 0;
        let total = products?.reduce((acc, product) => acc + product.total, 0) + Number(deliveryPrice);
        total = total.toFixed(2);
        return !isNaN(total) ? total : 0;
    }

    const handleAddressSelect = (value, place, setValue, clearSuggestions, getGeocode, getLatLng) => {
        let deliveryAddress = {}
        // console.log("📍 Address: ", place);
        const { description } = place;
        setValue(description, false);
        clearSuggestions();
    
        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
            // console.log("📍 Coordinates: ", { lat, lng });
            deliveryAddress = {
                address: description,
                lat: lat,
                lng: lng,
            };
        })
        .catch((error) => {
            console.log("😱 Error: ", error);
        });

        form.setFieldsValue({ deliveryAddress });
        setDeliveryAddress(deliveryAddress);

    };

    console.log("form", form.getFieldValue("deliveryAddress"));

    const handleSave = () => {
        console.log("form", form.getFieldsValue());
        form.getFieldsError();
        const values = form.getFieldsValue();
        console.log("values", values
        );
    }
    
    return (
        <Modal
        title="Nueva orden de compra"
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1200}
        >
        <Form form={form} layout="vertical">
            <Form.Item label="Proveedor" name="provider" rules={[{ required: true, message: "Selecciona un proveedor" }]}>
            <DebounceSelect
                fetchOptions={fetchProviders}
                placeholder="Buscar proveedor"
                style={{ width: "100%" }}
                allowClear
                showSearch
            />
            </Form.Item>
            

            
            <Form.Item label="Productos" name="products" rules={[{ required: true, message: "Selecciona al menos un producto" }]}>
                <ProductList form={form} setRenderId={setRenderId} />
            </Form.Item>
            {/* <Form.Item label="Entrega" name="delivery" rules={[{ required: true, message: "Selecciona un tipo de entrega" }]}>
                <Select
                    onChange={() => setRenderId((prev) => prev + 1)}
                    placeholder="Selecciona un tipo de entrega"
                    style={{ width: "100%" }}
                    allowClear
                >
                    <Select.Option value="Única">Entrega única</Select.Option>
                    <Select.Option value="Múltiple">Entregas múltiples</Select.Option>
                </Select>
            </Form.Item>
            {form.getFieldValue("delivery") === "Múltiple" && (
            <Space direction="horizontal" style={{ width: "100%" }}>
                <Form.Item label="Cantidad" name="deliveryQuantity" rules={[{ required: true, message: "Ingresa una cantidad" }]}>
                    <Input type="number" placeholder="Ingresa una cantidad" />
                </Form.Item>
                <Form.Item label="Frecuencia" name="frequency" rules={[{ required: true, message: "Selecciona una frecuencia" }]}>
                    <Select
                        placeholder="Selecciona una frecuencia"
                        style={{ width: "100%" }}
                        allowClear
                    >
                        <Select.Option value="Semanal">Semanal</Select.Option>
                        <Select.Option value="Quincenal">Quincenal</Select.Option>
                        <Select.Option value="Mensual">Mensual</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Fecha de inicio" name="startDate" rules={[{ required: true, message: "Selecciona una fecha" }]}>
                    <DatePicker style={{ width: "100%" }} placeholder="Selecciona una fecha" />
                </Form.Item>
            </Space>
            )} */}

        </Form>
        <Space direction="horizontal" style={{ width: "100%" }}>
        <Title level={4}>Total: ${String(calculateTotal()).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Title>
        </Space>
        <Button type="primary" htmlType="submit" style={{ position: "absolute", bottom: 20, right: 20 }} disabled={disableSave} onClick={handleSave}>
                Guardar
            </Button>
        </Modal>
    );
    }

export default NewPurchaseOrder;