import React from 'react';
import { Row, Col, Card, Statistic, Steps, Table } from 'antd';

var total = 0
var polvosFinos = 30

const dataSource = [
    { ingredient: "Sorgo", quantity: "40kg" },
    { ingredient: "Trigo", quantity: "10kg" },
    { ingredient: "Maíz", quantity: "18kg" },
];

const columns = [
    {
        title: "Ingrediente",
        dataIndex: "ingredient",
        key: "ingrediente",
        render: (text) => <h3>{text}</h3>,
    },
    {
        title: "Cantidad",
        dataIndex: "quantity",
        key: "quantity",
        render: (text) => <h3>{text}</h3>,
    },
];

dataSource.forEach(item => {
    const quantity = parseInt(item.quantity.replace('kg', ''), 10);
    total = total + quantity;
});

const FormulaWeightDetail = () => {
    return (
        <Row>
            <Col span={18} md={18}>
                <Card
                    bordered={false}
                    bodyStyle={{ padding: "0px" }}
                    style={{ padding: 20, width: "100%" }}
                    className="criclebox cardbody mb-24 header-solid"
                    title={<h2>Detalles de granos</h2>}
                >
                    <div className="ant-list-box table-responsive">
                        <Table
                            style={{ width: '100%' }}
                            dataSource={dataSource}
                            columns={columns}
                            rowKey={(record, index) => index}
                            pagination={true}
                        />
                    </div>
                </Card>
            </Col>
            <Col span={6} md={6}>
                <Row gutter={0}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ margin: '10px', width:"100%"}}
                        className="criclebox cardbody mb-24 header-solid"
                        title={
                            <Statistic
                                title="Peso Total de Mezcla"
                                value={total}
                                suffix="KG"
                                style={{ fontSize: '20px' }}
                            />
                        }
                    />
                </Row>
                <Row gutter={0}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ margin: '10px', width:"100%" }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={
                            <Statistic
                                title="Polvos Finos Agregados"
                                value={polvosFinos}
                                suffix="KG"
                                style={{ fontSize: '20px' }}
                            />
                        }
                    />
                </Row>
            </Col>
        </Row>
    )
}

export default FormulaWeightDetail;